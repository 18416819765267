<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row class="details_report">
                  <b-col lg="12" sm="12">
                    <b-row class="mb-1">
                      <b-col lg="12" sm="12">
                        <h5 class="font-weight-bold text-capitalize text-center p-1"><u>{{ $t('irriPumpInformation.report_gha_title') }}</u></h5>
                        <b-row>
                          <b-col><p class="text-right">{{ $t('irriPumpInformation.table_gha') }}: </p></b-col>
                        </b-row>
                        <b-row>
                          <b-col md="6">
                            <!-- <b-row>
                              <b-col md="4">
                                {{ $t('irriPumpInformation.zone_name') }} :
                              </b-col>
                              <b-col md="4">
                                {{ $t('globalTrans.upazila') }} : <b>{{ ($i18n.locale==='bn') ? searchInfo.upazila_name_bn  :  searchInfo.upazila_name }}</b>
                              </b-col>
                              <b-col md="4">
                                {{ $t('irriPumpInformation.region_name') }} :
                              </b-col>
                            </b-row> -->
                          </b-col>
                          <b-col md="6" class="text-right">
                            {{ $t('globalTrans.date') }} : {{today | dateFormat}}<br/>
                            {{ $t('irriPumpInformation.1st_part') }}
                          </b-col>
                        </b-row>
                        <b-table-simple small bordered hover responsive="" class="mt-2">
                          <b-tr>
                            <b-th rowspan="3">{{ $t('globalTrans.sl_no') }}</b-th>
                            <b-th colspan="4">{{ $t('irriPumpInformation.area_of_deep_tube_well') }}</b-th>
                            <b-th colspan="3">{{ $t('irriPumpInformation.placing_of_deep_tube_well') }}</b-th>
                            <b-th colspan="3">{{ $t('irriPumpInformation.rehabilitation_of_deep_tube_well') }}</b-th>
                            <b-th rowspan="3">{{ $t('irriPumpInformation.project_name') }}</b-th>
                            <b-th rowspan="3">{{ $t('irriPumpInformation.operator_name') }}</b-th>
                            <b-th rowspan="3">{{ $t('irriPumpInformation.deep_tube_well_nature') }}</b-th>
                            <b-th colspan="8">{{ $t('irriPumpInformation.information_on_goods') }}</b-th>
                          </b-tr>
                          <b-tr>
                            <b-th>{{ $t('globalTrans.union') }}</b-th>
                            <b-th>{{ $t('irriPumpInformation.mouza_no') }}</b-th>
                            <b-th>{{ $t('irriPumpInformation.jl_no') }}</b-th>
                            <b-th>{{ $t('irriPumpInformation.dag_no') }}</b-th>
                            <b-th>{{ $t('irriPumpInformation.date_of_excavation') }}</b-th>
                            <b-th>{{ $t('irriPumpInformation.date_of_commission') }}</b-th>
                            <b-th>{{ $t('irriPumpInformation.accepted_participation_s_fee_amount') }}</b-th>
                            <b-th>{{ $t('irriPumpInformation.date_of_excavation') }}</b-th>
                            <b-th>{{ $t('irriPumpInformation.date_of_commission') }}</b-th>
                            <b-th>{{ $t('irriPumpInformation.accepted_participation_s_fee_amount') }}</b-th>
                            <b-th colspan="3">{{ $t('irriPumpInformation.housing_pipe') }}</b-th>
                            <b-th colspan="3">{{ $t('irriPumpInformation.filter') }}</b-th>
                            <b-th colspan="2">{{ $t('irriPumpInformation.installed_column_pipe') }}</b-th>
                          </b-tr>
                          <b-tr>
                            <b-td></b-td>
                            <b-td></b-td>
                            <b-td></b-td>
                            <b-td></b-td>
                            <b-td></b-td>
                            <b-td></b-td>
                            <b-td></b-td>
                            <b-td></b-td>
                            <b-td></b-td>
                            <b-td></b-td>
                            <b-th>{{ $t('irriPumpInformation.ms') }}</b-th>
                            <b-th>{{ $t('irriPumpInformation.fg') }}</b-th>
                            <b-th>{{ $t('irriPumpInformation.updc') }}</b-th>
                            <b-th>{{ $t('irriPumpInformation.filter_ms') }}</b-th>
                            <b-th>{{ $t('irriPumpInformation.filter_fg') }}</b-th>
                            <b-th>{{ $t('irriPumpInformation.filter_updc') }}</b-th>
                            <b-th>{{ $t('irriPumpInformation.dia') }}</b-th>
                            <b-th>{{ $t('irriPumpInformation.amount') }}</b-th>
                          </b-tr>
                          <b-tr>
                            <b-td>{{ $n(1) }}</b-td>
                            <b-td>{{ $n(2) }}</b-td>
                            <b-td>{{ $n(3) }}</b-td>
                            <b-td>{{ $n(4) }}</b-td>
                            <b-td>{{ $n(5) }}</b-td>
                            <b-td>{{ $n(6) }}</b-td>
                            <b-td>{{ $n(7) }}</b-td>
                            <b-td>{{ $n(8) }}</b-td>
                            <b-td>{{ $n(9) }}</b-td>
                            <b-td>{{ $n(10) }}</b-td>
                            <b-td>{{ $n(11) }}</b-td>
                            <b-td>{{ $n(12) }}</b-td>
                            <b-td>{{ $n(13) }}</b-td>
                            <b-td>{{ $n(14) }}</b-td>
                            <b-td>{{ $n(15) }}</b-td>
                            <b-td>{{ $n(16) }}</b-td>
                            <b-td>{{ $n(17) }}</b-td>
                            <b-td>{{ $n(18) }}</b-td>
                            <b-td>{{ $n(19) }}</b-td>
                            <b-td>{{ $n(20) }}</b-td>
                            <b-td>{{ $n(21) }}</b-td>
                            <b-td>{{ $n(22) }}</b-td>
                          </b-tr>
                          <b-tr v-for="(deepTube, index) in deepTubwell" :key="index">
                            <b-td>{{index+1}}</b-td>
                            <b-td>{{ ($i18n.locale==='bn') ? deepTube.union_name_bn  :  deepTube.union_name }}</b-td>
                            <b-td>{{ deepTube.mouza_no }}</b-td>
                            <b-td>{{ deepTube.jl_no }}</b-td>
                            <b-td>{{ deepTube.plot_no }}</b-td>
                            <b-td>{{ deepTube.install_date_of_excavation | dateFormat }}</b-td>
                            <b-td>{{ deepTube.install_date_of_commission | dateFormat }}</b-td>
                            <b-td>{{ deepTube.install_accepted_participation }}</b-td>
                            <b-td>{{ deepTube.rehab_date_of_excavation | dateFormat }}</b-td>
                            <b-td>{{ deepTube.rehab_date_of_commission | dateFormat }}</b-td>
                            <b-td>{{ deepTube.rehab_accepted_participation }}</b-td>
                            <b-td>{{ deepTube.project_name }}</b-td>
                            <b-td>{{ deepTube.operator_name }}</b-td>
                            <b-td>{{ deepTube.deep_tube_well_nature }}</b-td>
                            <b-td>{{  $n(deepTube.ms) }}</b-td>
                            <b-td>{{  $n(deepTube.fg) }}</b-td>
                            <b-td>{{  $n(deepTube.updc) }}</b-td>
                            <b-td>{{  $n(deepTube.filter_ms) }}</b-td>
                            <b-td>{{  $n(deepTube.filter_fg) }}</b-td>
                            <b-td>{{  $n(deepTube.filter_updc) }}</b-td>
                            <b-td>{{ $n(deepTube.dia) }}</b-td>
                            <b-td>{{ $n(deepTube.amount) }}</b-td>
                          </b-tr>
                        </b-table-simple>
                      </b-col>
                    </b-row>
                    <b-row class="mb-1">
                      <b-col lg="12" sm="12">
                        <b-row>
                          <b-col class="text-right">{{ $t('irriPumpInformation.2nd_part') }}: </b-col>
                        </b-row>
                        <div>
                          <b-table-simple small bordered hover responsive="" class="mt-2">
                            <b-thead>
                              <b-tr>
                                <b-th rowspan="3">{{ $t('globalTrans.sl_no') }}</b-th>
                                <b-th colspan="9">{{ $t('irriPumpInformation.technical_details') }}</b-th>
                                <b-th colspan="14">{{ $t('irriPumpInformation.2019_2020_years') }} {{ $t('irriPumpInformation.amount_irrigated_land') }}</b-th>
                                <b-th colspan="3">{{ $t('irriPumpInformation.beneficial_farmer') }}</b-th>
                              </b-tr>
                              <b-tr>
                                <b-th colspan="3">{{ $t('irriPumpInformation.vetical_motor') }}</b-th>
                                <b-th colspan="5">{{ $t('irriPumpInformation.turbine_submersible_motor') }}</b-th>
                                <b-th rowspan="2">{{ $t('irriPumpInformation.command_area') }}</b-th>
                                <b-th colspan="3">{{ $t('irriPumpInformation.kharif_1') }}</b-th>
                                <b-th colspan="3">{{ $t('irriPumpInformation.kharif_2') }}</b-th>
                                <b-th colspan="8">{{ $t('irriPumpInformation.robi') }}</b-th>
                                <b-th rowspan="2">{{ $t('irriPumpInformation.actual') }}</b-th>
                                <b-th rowspan="2">{{ $t('irriPumpInformation.barga') }}</b-th>
                                <b-th rowspan="2">{{ $t('irriPumpInformation.total') }}</b-th>
                              </b-tr>
                              <b-tr>
                                <b-th>{{ $t('irriPumpInformation.make_o_model') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.power') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.unit_consumption') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.make_o_model') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.power') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.unit_consumption') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.head') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.discharge') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.aus') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.others') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.total') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.aman') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.others') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.total') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.borou') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.wheat') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.potato') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.corn') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.mustard') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.lentils') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.vegetables') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.total') }}</b-th>
                              </b-tr>
                            </b-thead>
                            <tbody>
                              <b-tr>
                                <b-td></b-td>
                                <b-td>{{ $n(23) }}</b-td>
                                <b-td>{{ $n(24) }}</b-td>
                                <b-td>{{ $n(25) }}</b-td>
                                <b-td>{{ $n(26) }}</b-td>
                                <b-td>{{ $n(27) }}</b-td>
                                <b-td>{{ $n(28) }}</b-td>
                                <b-td>{{ $n(29) }}</b-td>
                                <b-td>{{ $n(30) }}</b-td>
                                <b-td>{{ $n(31) }}</b-td>
                                <b-td>{{ $n(32) }}</b-td>
                                <b-td>{{ $n(33) }}</b-td>
                                <b-td>{{ $n(34) }}</b-td>
                                <b-td>{{ $n(35) }}</b-td>
                                <b-td>{{ $n(36) }}</b-td>
                                <b-td>{{ $n(37) }}</b-td>
                                <b-td>{{ $n(38) }}</b-td>
                                <b-td>{{ $n(39) }}</b-td>
                                <b-td>{{ $n(40) }}</b-td>
                                <b-td>{{ $n(41) }}</b-td>
                                <b-td>{{ $n(42) }}</b-td>
                                <b-td>{{ $n(43) }}</b-td>
                                <b-td>{{ $n(44) }}</b-td>
                                <b-td>{{ $n(45) }}</b-td>
                                <b-td>{{ $n(46) }}</b-td>
                                <b-td>{{ $n(47) }}</b-td>
                                <b-td>{{ $n(48) }}</b-td>
                              </b-tr>
                              <b-tr  v-for="(deepTube, index1) in deepTubwell" :key="index1">
                                <b-td>{{ $n(index1+1) }}</b-td>
                                <b-td>{{deepTube.vertical_make_o_model}}</b-td>
                                <b-td>{{deepTube.vertical_power}}</b-td>
                                <b-td>{{deepTube.vertical_unit_consumption  }}</b-td>
                                <b-td>{{deepTube.turbine_make_o_model}}</b-td>
                                <b-td>{{$n(deepTube.turbine_power)}}</b-td>
                                <b-td>{{$n(deepTube.turbine_unit_consumption)  }}</b-td>
                                <b-td>{{$n(deepTube.head)  }}</b-td>
                                <b-td>{{$n(deepTube.discharge)  }}</b-td>
                                <b-td>{{$n(deepTube.command_area)  }}</b-td>
                                <b-td>{{$n(deepTube.kharif_1_aus)  }}</b-td>
                                <b-td>{{$n(deepTube.kharif_1_others)  }}</b-td>
                                <b-td>{{$n(deepTube.kharif_1_total)  }}</b-td>
                                <b-td>{{$n(deepTube.kharif_2_aman)  }}</b-td>
                                <b-td>{{$n(deepTube.kharif_2_others)  }}</b-td>
                                <b-td>{{$n(deepTube.kharif_2_total)  }}</b-td>
                                <b-td>{{$n(deepTube.borou)  }}</b-td>
                                <b-td>{{$n(deepTube.wheat)  }}</b-td>
                                <b-td>{{$n(deepTube.potato)  }}</b-td>
                                <b-td>{{$n(deepTube.corn)  }}</b-td>
                                <b-td>{{$n(deepTube.mustard)  }}</b-td>
                                <b-td>{{$n(deepTube.lentils)  }}</b-td>
                                <b-td>{{$n(deepTube.vegetables)  }}</b-td>
                                <b-td>{{$n(deepTube.robi_total)  }}</b-td>
                                <b-td>{{$n(deepTube.actual)  }}</b-td>
                                <b-td>{{$n(deepTube.barga)  }}</b-td>
                                <b-td>{{$n(deepTube.beneficial_farmer_total)  }}</b-td>
                              </b-tr>
                            </tbody>
                          </b-table-simple>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="mb-1">
                      <b-col lg="12" sm="12">
                        <b-row>
                          <b-col class="text-right">{{ $t('irriPumpInformation.3rd_part') }}: </b-col>
                        </b-row>
                        <div>
                          <b-table-simple small bordered hover class="mt-2">
                            <b-thead>
                              <b-tr>
                                <th colspan="3">{{ $t('irriPumpInformation.tot_uses_unit') }}</th>
                                <th colspan="6">{{ $t('irriPumpInformation.irri_adai_descrip') }}</th>
                                <th colspan="5">{{ $t('irriPumpInformation.mnge_ment_cost') }}</th>
                                <th rowspan="2" style="width:7%">{{ $t('irriPumpInformation.tot_income') }}</th>
                                <th rowspan="2" style="width:7%">{{ $t('irriPumpInformation.ganku_details') }}</th>
                                <th rowspan="2" style="width:8%">{{ $t('irriPumpInformation.super_v_mechanic') }}</th>
                                <th rowspan="2" style="width:5%">{{ $t('irriPumpInformation.super_v_higher_engr') }}</th>
                                <th rowspan="2" style="width:10%">{{ $t('irriPumpInformation.comments') }}</th>
                              </b-tr>
                              <b-tr>
                                <b-th>{{ $t('irriPumpInformation.start_reading') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.end_reading') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.total') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.hourly_used_unit') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.tot_active_hour') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.hourly_irri_charge') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.recoverable_irri_payment') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.collected_irri_payment') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.tot_biddut_cost') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.operator_salary') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.maintance_cost') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.other_cost') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.tot_cost') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.tot_income') }}</b-th>
                              </b-tr>
                            </b-thead>
                            <b-tbody>
                              <b-tr>
                                <b-td>{{ $n(49) }}</b-td>
                                <b-td>{{ $n(50) }}</b-td>
                                <b-td>{{ $n(51) }}</b-td>
                                <b-td>{{ $n(52) }}</b-td>
                                <b-td>{{ $n(53) }}</b-td>
                                <b-td>{{ $n(54) }}</b-td>
                                <b-td>{{ $n(55) }}</b-td>
                                <b-td>{{ $n(56) }}</b-td>
                                <b-td>{{ $n(57) }}</b-td>
                                <b-td>{{ $n(58) }}</b-td>
                                <b-td>{{ $n(59) }}</b-td>
                                <b-td>{{ $n(60) }}</b-td>
                                <b-td>{{ $n(61) }}</b-td>
                                <b-td>{{ $n(62) }}</b-td>
                                <b-td>
                                  {{ $n(63) }} <br/>
                                  {{ $n(56) }} - {{ $n(62) }}
                                </b-td>
                                <b-td>{{ $n(64) }}</b-td>
                                <b-td>{{ $n(65) }}</b-td>
                                <b-td>{{ $n(66) }}</b-td>
                                <b-td>{{ $n(67) }}</b-td>
                              </b-tr>
                              <b-tr  v-for="(deepTube, index2) in deepTubwell" :key="index2">
                                <b-td>{{$n(deepTube.start_reading)}}</b-td>
                                <b-td>{{$n(deepTube.end_reading)}}</b-td>
                                <b-td>{{$n(deepTube.total_uses_unit)}}</b-td>
                                <b-td>{{$n(deepTube.hourly_used_unit)}}</b-td>
                                <b-td>{{$n(deepTube.total_active_hour)}}</b-td>
                                <b-td>{{$n(deepTube.hourly_irri_charge)}}</b-td>
                                <b-td>{{$n(deepTube.recoverable_irri_payment)}}</b-td>
                                <b-td>{{$n(deepTube.collected_irri_payment)}}</b-td>
                                <b-td>{{$n(deepTube.total_electricity_cost)}}</b-td>
                                <b-td>{{$n(deepTube.operator_salary)}}</b-td>
                                <b-td>{{$n(deepTube.maintance_cost)}}</b-td>
                                <b-td>{{$n(deepTube.other_cost)}}</b-td>
                                <b-td>{{$n(deepTube.total_cost)}}</b-td>
                                <b-td>{{$n(deepTube.total_income)}}</b-td>
                                <b-td>{{ $n(deepTube.collected_irri_payment - deepTube.total_income)}}</b-td>
                                <b-td>{{deepTube.ganku_details}}</b-td>
                                <b-td>{{deepTube.super_v_mechanic}}</b-td>
                                <b-td>{{deepTube.super_v_higher_engr}}</b-td>
                                <b-td>{{deepTube.comments}}</b-td>
                              </b-tr>
                            </b-tbody>
                          </b-table-simple>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="mb-1">
                      <b-col lg="12" sm="12" style="height:70px">
                        N.B . {{ $t('irriPumpInformation.fixture_design') }}
                      </b-col>
                      <b-col lg="4" sm="4" class="text-center">
                        {{ $t('irriPumpInformation.gh_footer_1') }}
                        <div class="col-12"></div>
                        {{ $t('irriPumpInformation.bmda') }} ............. {{ $t('irriPumpInformation.Zone') }}
                      </b-col>
                      <b-col lg="4" sm="4" class="text-center">
                        {{ $t('irriPumpInformation.gh_footer_2') }}
                        <div class="col-12"></div>
                        {{ $t('irriPumpInformation.bmda') }} ............. {{ $t('irriPumpInformation.Zone') }}
                      </b-col>
                      <b-col lg="4" sm="4" class="text-center">
                        {{ $t('irriPumpInformation.gh_footer_3') }}
                        <div class="col-12"></div>
                        {{ $t('irriPumpInformation.bmda') }} ............. {{ $t('irriPumpInformation.Zone') }}
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
export default {
  name: 'FormLayout',
  props: ['id', 'item', 'search'],
  created () {
    this.deepTubwell = this.item
    this.searchDataFind()
  },
  data () {
    return {
      deepTubwell: '',
      searchInfo: {
        upazila_name: '',
        upazila_name_bn: ''
      },
      today: new Date().toDateString(),
      slOffset: 1
    }
  },
  computed: {
    location_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('org_pro.organization'), val: this.deepTubwell.org_name_bn, key1: this.$t('irriPumpInformation.pump_id'), val1: this.$n(this.deepTubwell.pump_id) },
          { key: this.$t('complain.division'), val: this.deepTubwell.division_name_bn, key1: this.$t('complain.district'), val1: this.deepTubwell.district_name_bn },
          { key: this.$t('complain.upazila'), val: this.deepTubwell.upazilla_name_bn, key1: this.$t('complain.union'), val1: this.deepTubwell.union_name_bn },
          { key: this.$t('irriPumpInformation.mouza_no'), val: this.$n(this.deepTubwell.mouza_no, { useGrouping: false }), key1: this.$t('irriPumpInformation.jl_no'), val1: this.$n(this.deepTubwell.jl_no, { useGrouping: false }) },
          { key: this.$t('irriPumpInformation.plot_no'), val: this.$n(this.deepTubwell.plot_no, { useGrouping: false }) }
        ]
      } else {
          return [
            { key: this.$t('org_pro.organization'), val: this.deepTubwell.org_name, key1: this.$t('irriPumpInformation.pump_id'), val1: this.deepTubwell.pump_id },
            { key: this.$t('complain.division'), val: this.deepTubwell.division_name, key1: this.$t('complain.district'), val1: this.deepTubwell.district_name },
            { key: this.$t('complain.upazila'), val: this.deepTubwell.upazilla_name, key1: this.$t('complain.union'), val1: this.deepTubwell.union_name },
            { key: this.$t('irriPumpInformation.mouza_no'), val: this.deepTubwell.mouza_no, key1: this.$t('irriPumpInformation.jl_no'), val1: this.deepTubwell.jl_no },
            { key: this.$t('irriPumpInformation.plot_no'), val: this.deepTubwell.plot_no }
          ]
      }
    },
    installation_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.date_of_excavation'), val: this.$d(new Date(this.deepTubwell.install_date_of_excavation)), key1: this.$t('irriPumpInformation.date_of_commission'), val1: this.$d(new Date(this.deepTubwell.install_date_of_commission)), key2: this.$t('irriPumpInformation.accepted_participation'), val2: this.$n(this.deepTubwell.install_accepted_participation) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.date_of_excavation'), val: this.$d(new Date(this.deepTubwell.install_date_of_excavation)), key1: this.$t('irriPumpInformation.date_of_commission'), val1: this.$d(new Date(this.deepTubwell.install_date_of_commission)), key2: this.$t('irriPumpInformation.accepted_participation'), val2: this.$n(this.deepTubwell.install_accepted_participation) }
          ]
      }
    },
    rehabilitation_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.date_of_excavation'), val: this.$d(new Date(this.deepTubwell.rehab_date_of_excavation)), key1: this.$t('irriPumpInformation.date_of_commission'), val1: this.$d(new Date(this.deepTubwell.rehab_date_of_commission)), key2: this.$t('irriPumpInformation.accepted_participation'), val2: this.$n(this.deepTubwell.rehab_accepted_participation) },
          { key: this.$t('irriPumpInformation.project_name'), val: this.deepTubwell.project_name, key1: this.$t('irriPumpInformation.deep_tube_well_nature'), val1: this.deepTubwell.deep_tube_well_nature, key2: this.$t('irriPumpInformation.operator_name'), val2: this.deepTubwell.operator_name }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.date_of_excavation'), val: this.$d(new Date(this.deepTubwell.rehab_date_of_excavation)), key1: this.$t('irriPumpInformation.date_of_commission'), val1: this.$d(new Date(this.deepTubwell.rehab_date_of_commission)), key2: this.$t('irriPumpInformation.accepted_participation'), val2: this.$n(this.deepTubwell.rehab_accepted_participation) },
          { key: this.$t('irriPumpInformation.project_name'), val: this.deepTubwell.project_name, key1: this.$t('irriPumpInformation.deep_tube_well_nature'), val1: this.deepTubwell.deep_tube_well_nature, key2: this.$t('irriPumpInformation.operator_name'), val2: this.deepTubwell.operator_name }
          ]
      }
    },
    housing_pipe_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.ms'), val: this.$n(this.deepTubwell.ms), key1: this.$t('irriPumpInformation.fg'), val1: this.$n(this.deepTubwell.fg), key2: this.$t('irriPumpInformation.updc'), val2: this.$n(this.deepTubwell.updc) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.ms'), val: this.$n(this.deepTubwell.ms), key1: this.$t('irriPumpInformation.fg'), val1: this.$n(this.deepTubwell.fg), key2: this.$t('irriPumpInformation.updc'), val2: this.$n(this.deepTubwell.updc) }
          ]
      }
    },
    filter_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.filter_ms'), val: this.$n(this.deepTubwell.filter_ms), key1: this.$t('irriPumpInformation.filter_fg'), val1: this.$n(this.deepTubwell.filter_fg), key2: this.$t('irriPumpInformation.updc'), val2: this.$n(this.deepTubwell.filter_updc) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.filter_ms'), val: this.$n(this.deepTubwell.filter_ms), key1: this.$t('irriPumpInformation.filter_fg'), val1: this.$n(this.deepTubwell.filter_fg), key2: this.$t('irriPumpInformation.updc'), val2: this.$n(this.deepTubwell.filter_updc) }
          ]
      }
    },
    installed_column_pipe_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.dia'), val: this.$n(this.deepTubwell.dia), key1: this.$t('irriPumpInformation.amount'), val1: this.$n(this.deepTubwell.amount), key2: '', val2: '' }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.dia'), val: this.$n(this.deepTubwell.dia), key1: this.$t('irriPumpInformation.amount'), val1: this.$n(this.deepTubwell.amount), key2: '', val2: '' }
          ]
      }
    },
    vertical_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.make_o_model'), val: this.deepTubwell.vertical_make_o_model, key1: this.$t('irriPumpInformation.unit_consumption'), val1: this.$n(this.deepTubwell.vertical_unit_consumption), key2: this.$t('irriPumpInformation.power'), val2: this.$n(this.deepTubwell.vertical_power) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.make_o_model'), val: this.deepTubwell.vertical_make_o_model, key1: this.$t('irriPumpInformation.unit_consumption'), val1: this.$n(this.deepTubwell.vertical_unit_consumption), key2: this.$t('irriPumpInformation.power'), val2: this.$n(this.deepTubwell.vertical_power) }
          ]
      }
    },
    turbine_submersible_motor_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.make_o_model'), val: this.deepTubwell.turbine_make_o_model, key1: this.$t('irriPumpInformation.unit_consumption'), val1: this.$n(this.deepTubwell.turbine_unit_consumption), key2: this.$t('irriPumpInformation.power'), val2: this.$n(this.deepTubwell.turbine_power) },
          { key: this.$t('irriPumpInformation.head'), val: this.$n(this.deepTubwell.head), key1: this.$t('irriPumpInformation.discharge'), val1: this.$n(this.deepTubwell.discharge), key2: this.$t('irriPumpInformation.command_area'), val2: this.$n(this.deepTubwell.command_area) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.make_o_model'), val: this.deepTubwell.turbine_make_o_model, key1: this.$t('irriPumpInformation.unit_consumption'), val1: this.$n(this.deepTubwell.turbine_unit_consumption), key2: this.$t('irriPumpInformation.power'), val2: this.$n(this.deepTubwell.turbine_power) },
          { key: this.$t('irriPumpInformation.head'), val: this.$n(this.deepTubwell.head), key1: this.$t('irriPumpInformation.discharge'), val1: this.$n(this.deepTubwell.discharge), key2: this.$t('irriPumpInformation.command_area'), val2: this.$n(this.deepTubwell.command_area) }
          ]
      }
    },
    kharif_1_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.aus'), val: this.$n(this.deepTubwell.kharif_1_aus), key1: this.$t('irriPumpInformation.others'), val1: this.$n(this.deepTubwell.kharif_1_others), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.kharif_1_total) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.aus'), val: this.$n(this.deepTubwell.kharif_1_aus), key1: this.$t('irriPumpInformation.others'), val1: this.$n(this.deepTubwell.kharif_1_others), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.kharif_1_total) }
          ]
      }
    },
    kharif_2_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.aman'), val: this.$n(this.deepTubwell.kharif_2_aman), key1: this.$t('irriPumpInformation.others'), val1: this.$n(this.deepTubwell.kharif_2_others), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.kharif_2_total) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.aman'), val: this.$n(this.deepTubwell.kharif_2_aman), key1: this.$t('irriPumpInformation.others'), val1: this.$n(this.deepTubwell.kharif_2_others), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.kharif_2_total) }
          ]
      }
    },
    robi_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.borou'), val: this.$n(this.deepTubwell.borou), key1: this.$t('irriPumpInformation.mustard'), val1: this.$n(this.deepTubwell.mustard), key2: this.$t('irriPumpInformation.wheat'), val2: this.$n(this.deepTubwell.wheat) },
          { key: this.$t('irriPumpInformation.lentils'), val: this.$n(this.deepTubwell.lentils), key1: this.$t('irriPumpInformation.potato'), val1: this.$n(this.deepTubwell.potato), key2: this.$t('irriPumpInformation.vegetables'), val2: this.$n(this.deepTubwell.vegetables) },
          { key: this.$t('irriPumpInformation.corn'), val: this.$n(this.deepTubwell.corn), key1: this.$t('irriPumpInformation.total'), val1: this.$n(this.deepTubwell.robi_total) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.borou'), val: this.$n(this.deepTubwell.borou), key1: this.$t('irriPumpInformation.mustard'), val1: this.$n(this.deepTubwell.mustard), key2: this.$t('irriPumpInformation.wheat'), val2: this.$n(this.deepTubwell.wheat) },
          { key: this.$t('irriPumpInformation.lentils'), val: this.$n(this.deepTubwell.lentils), key1: this.$t('irriPumpInformation.potato'), val1: this.$n(this.deepTubwell.potato), key2: this.$t('irriPumpInformation.vegetables'), val2: this.$n(this.deepTubwell.vegetables) },
          { key: this.$t('irriPumpInformation.corn'), val: this.$n(this.deepTubwell.corn), key1: this.$t('irriPumpInformation.total'), val1: this.$n(this.deepTubwell.robi_total) }
          ]
      }
    },
    beneficial_farmer_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.actual'), val: this.$n(this.deepTubwell.actual), key1: this.$t('irriPumpInformation.barga'), val1: this.$n(this.deepTubwell.barga), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.beneficial_farmer_total) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.actual'), val: this.$n(this.deepTubwell.actual), key1: this.$t('irriPumpInformation.barga'), val1: this.$n(this.deepTubwell.barga), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.beneficial_farmer_total) }
          ]
      }
    },
    tot_uses_unit_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.start_reading'), val: this.$n(this.deepTubwell.start_reading), key1: this.$t('irriPumpInformation.end_reading'), val1: this.$n(this.deepTubwell.end_reading), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.total_uses_unit) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.start_reading'), val: this.$n(this.deepTubwell.start_reading), key1: this.$t('irriPumpInformation.end_reading'), val1: this.$n(this.deepTubwell.end_reading), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.total_uses_unit) }
          ]
      }
    },
    irri_adai_descrip_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.hourly_used_unit'), val: this.$n(this.deepTubwell.hourly_used_unit), key1: this.$t('irriPumpInformation.tot_active_hour'), val1: this.$n(this.deepTubwell.total_active_hour), key2: this.$t('irriPumpInformation.hourly_irri_charge'), val2: this.$n(this.deepTubwell.hourly_irri_charge) },
          { key: this.$t('irriPumpInformation.recoverable_irri_payment'), val: this.$n(this.deepTubwell.recoverable_irri_payment), key1: this.$t('irriPumpInformation.collected_irri_payment'), val1: this.$n(this.deepTubwell.collected_irri_payment), key2: this.$t('irriPumpInformation.unpaid_money'), val2: this.$n(this.deepTubwell.unpaid_money) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.hourly_used_unit'), val: this.$n(this.deepTubwell.hourly_used_unit), key1: this.$t('irriPumpInformation.total_active_hour'), val1: this.$n(this.deepTubwell.total_active_hour), key2: this.$t('irriPumpInformation.hourly_irri_charge'), val2: this.$n(this.deepTubwell.hourly_irri_charge) },
          { key: this.$t('irriPumpInformation.recoverable_irri_payment'), val: this.$n(this.deepTubwell.recoverable_irri_payment), key1: this.$t('irriPumpInformation.collected_irri_payment'), val1: this.$n(this.deepTubwell.collected_irri_payment), key2: this.$t('irriPumpInformation.unpaid_money'), val2: this.$n(this.deepTubwell.unpaid_money) }
          ]
      }
    },
    mnge_ment_cost_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.tot_biddut_cost'), val: this.$n(this.deepTubwell.total_electricity_cost), key1: this.$t('irriPumpInformation.operator_salary'), val1: this.$n(this.deepTubwell.operator_salary), key2: this.$t('irriPumpInformation.maintance_cost'), val2: this.$n(this.deepTubwell.maintance_cost) },
          { key: this.$t('irriPumpInformation.other_cost'), val: this.$n(this.deepTubwell.other_cost), key1: this.$t('irriPumpInformation.tot_cost'), val1: this.$n(this.deepTubwell.total_cost), key2: this.$t('irriPumpInformation.tot_income'), val2: this.$n(this.deepTubwell.total_income) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.tot_biddut_cost'), val: this.$n(this.deepTubwell.total_electricity_cost), key1: this.$t('irriPumpInformation.operator_salary'), val1: this.$n(this.deepTubwell.operator_salary), key2: this.$t('irriPumpInformation.maintance_cost'), val2: this.$n(this.deepTubwell.maintance_cost) },
          { key: this.$t('irriPumpInformation.other_cost'), val: this.$n(this.deepTubwell.other_cost), key1: this.$t('irriPumpInformation.tot_cost'), val1: this.$n(this.deepTubwell.total_cost), key2: this.$t('irriPumpInformation.tot_income'), val2: this.$n(this.deepTubwell.total_income) }
          ]
      }
    },
    mnge_ment_cost_bottom_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.ganku_details'), val: this.deepTubwell.ganku_details },
          { key: this.$t('irriPumpInformation.super_v_mechanic'), val: this.deepTubwell.super_v_mechanic },
          { key: this.$t('irriPumpInformation.super_v_higher_engr'), val: this.deepTubwell.super_v_higher_engr },
          { key: this.$t('irriPumpInformation.comments'), val: this.deepTubwell.comments }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.ganku_details'), val: this.deepTubwell.ganku_details },
          { key: this.$t('irriPumpInformation.super_v_mechanic'), val: this.deepTubwell.super_v_mechanic },
          { key: this.$t('irriPumpInformation.super_v_higher_engr'), val: this.deepTubwell.super_v_higher_engr },
          { key: this.$t('irriPumpInformation.comments'), val: this.deepTubwell.comments }
          ]
      }
    }
  },
  methods: {
    searchDataFind () {
      if (this.search.upazilla_id) {
        const upazilaList = this.$store.state.commonObj.upazilaList
        const upazilaObject = upazilaList.find(upazilla => upazilla.value === this.search.upazilla_id)
        this.searchInfo.upazila_name = upazilaObject ? upazilaObject.text_en : ''
        this.searchInfo.upazila_name_bn = upazilaObject ? upazilaObject.text_bn : ''
      }
    }
  }
}
</script>
<style lang="scss">
  .details_report {
    font-size: 8px;
    color: #000;
    h5 {
      color: green !important;
      font-size: 12px !important;
    }
    .table-bordered td:nth-child(2n+1) {
      font-weight: 700;
    }
    .table-bordered th {
      text-align: center;
      font-weight: 900 !important;
      vertical-align: middle !important;
    }
    .table-bordered tr td {
      text-align: center;
      vertical-align: middle !important;
    }
  }
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
</style>
