<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row class="details_report">
                  <b-col lg="12" sm="12">
                    <b-row class="mb-1">
                      <b-col lg="12" sm="12">
                        <h5 class="font-weight-bold text-capitalize text-center p-1"><u>{{ $t('irriPumpInformation.report_ko_title') }}</u></h5>
                        <b-row>
                          <b-col><p class="text-right">{{ $t('irriPumpInformation.table_ko') }}: </p></b-col>
                        </b-row>
                        <b-row>
                          <b-col class="text-right">{{ $t('irriPumpInformation.1st_part') }}: </b-col>
                        </b-row>
                        <b-table-simple small bordered hover responsive="" class="mt-2">
                          <b-thead>
                            <b-tr>
                              <b-th rowspan="4">{{ $t('globalTrans.sl_no') }}</b-th>
                              <b-th rowspan="4">{{ $t('irriPumpInformation.zone_name') }}</b-th>
                              <b-th rowspan="4">{{ $t('globalTrans.upazila') }}</b-th>
                              <b-th rowspan="4">{{ $t('irriPumpInformation.total_placement_gonokur_number') }}</b-th>
                              <b-th colspan="3">{{ $t('irriPumpInformation.total_commission_gonokur_number') }}</b-th>
                              <b-th colspan="3">{{ $t('irriPumpInformation.total_used_gonokur_number') }}</b-th>
                              <b-th rowspan="4">{{ $t('irriPumpInformation.upazila_average_unit_consumption') }}</b-th>
                              <b-th colspan="10">{{ $t('irriPumpInformation.details_of_deep_tube_well_use_and_irrigation_area') }}</b-th>
                            </b-tr>
                            <b-tr>
                              <b-th rowspan="3">{{ $t('irriPumpInformation.vetical_motor') }}</b-th>
                              <b-th rowspan="3">{{ $t('irriPumpInformation.sub_pump') }}</b-th>
                              <b-th rowspan="3">{{ $t('irriPumpInformation.total') }}</b-th>
                              <b-th rowspan="3">{{ $t('irriPumpInformation.vetical_motor') }}</b-th>
                              <b-th rowspan="3">{{ $t('irriPumpInformation.sub_pump') }}</b-th>
                              <b-th rowspan="3">{{ $t('irriPumpInformation.total') }}</b-th>
                              <b-th colspan="5">{{ $t('irriPumpInformation.kharif_1') }}</b-th>
                              <b-th colspan="5">{{ $t('irriPumpInformation.kharif_2') }}</b-th>
                            </b-tr>
                            <b-tr>
                              <b-th rowspan="2">{{ $t('irriPumpInformation.total') }} {{ $t('irriPumpInformation.total_used_gonokur_number') }}</b-th>
                              <b-th colspan="2">{{ $t('irriPumpInformation.t-') }} {{ $t('irriPumpInformation.aus') }}</b-th>
                              <b-th rowspan="2">{{ $t('irriPumpInformation.other_irrigation_area') }} </b-th>
                              <b-th rowspan="2">
                                {{ $t('irriPumpInformation.total_irrigation_area') }}
                                <br/>
                                {{ $n(16) }} =  {{ $n(13) }} + {{ $n(15) }}
                              </b-th>
                              <b-th rowspan="2">{{ $t('irriPumpInformation.total') }} {{ $t('irriPumpInformation.total_used_gonokur_number') }}</b-th>
                              <b-th colspan="2">{{ $t('irriPumpInformation.t-') }} {{ $t('irriPumpInformation.aman') }}</b-th>
                              <b-th rowspan="2">{{ $t('irriPumpInformation.other_irrigation_area') }}</b-th>
                              <b-th rowspan="2">
                                {{ $t('irriPumpInformation.total_irrigation_area') }}
                                <br/>
                                {{ $n(21) }} =  {{ $n(18) }} + {{ $n(20) }}
                              </b-th>
                            </b-tr>
                            <b-tr>
                              <b-th>{{ $t('irriPumpInformation.sech_area_hector') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.irrigation_cost_per_hectare') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.sech_area_hector') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.irrigation_cost_per_hectare') }}</b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr>
                              <b-td>{{ $n(1) }}</b-td>
                              <b-td>{{ $n(2) }}</b-td>
                              <b-td>{{ $n(3) }}</b-td>
                              <b-td>{{ $n(4) }}</b-td>
                              <b-td>{{ $n(5) }}</b-td>
                              <b-td>{{ $n(6) }}</b-td>
                              <b-td>{{ $n(7) }}</b-td>
                              <b-td>{{ $n(8) }}</b-td>
                              <b-td>{{ $n(9) }}</b-td>
                              <b-td>{{ $n(10) }}</b-td>
                              <b-td>{{ $n(11) }}</b-td>
                              <b-td>{{ $n(12) }}</b-td>
                              <b-td>{{ $n(13) }}</b-td>
                              <b-td>{{ $n(14) }}</b-td>
                              <b-td>{{ $n(15) }}</b-td>
                              <b-td>{{ $n(16) }}</b-td>
                              <b-td>{{ $n(17) }}</b-td>
                              <b-td>{{ $n(18) }}</b-td>
                              <b-td>{{ $n(19) }}</b-td>
                              <b-td>{{ $n(20) }}</b-td>
                              <b-td>{{ $n(21) }}</b-td>
                            </b-tr>
                            <b-tr v-for="(deepTube, index) in deepTubwell" :key="index">
                              <b-td>{{ $n(index+1) }}</b-td>
                              <b-td>{{ ($i18n.locale==='bn') ? deepTube.office_name_bn  :  deepTube.office_name }}</b-td>
                              <b-td>{{ ($i18n.locale==='bn') ? deepTube.union_name_bn  :  deepTube.union_name }}</b-td>
                              <b-td>
                                {{ $n(deepTube.kharif_1_total_gonku + deepTube.kharif_2_total_gonku) }}
                              </b-td>
                              <b-td></b-td>
                              <b-td></b-td>
                              <b-td></b-td>
                              <b-td></b-td>
                              <b-td></b-td>
                              <b-td></b-td>
                              <b-td>
                                {{$n((deepTube.vertical_unit_consumption + deepTube.turbine_unit_consumption)/2)}}
                              </b-td>
                              <b-td>{{ $n(deepTube.kharif_1_total_gonku)}}</b-td>
                              <b-td>{{ $n(deepTube.kharif_1_aus)}}</b-td>
                              <b-td>{{ $n(deepTube.kharif_1_aus_per_hector_cost)}}</b-td>
                              <b-td>{{ $n(deepTube.kharif_1_others)}}</b-td>
                              <b-td>{{ $n(deepTube.kharif_1_aus + deepTube.kharif_1_others)}}</b-td>
                              <b-td>{{ $n(deepTube.kharif_2_total_gonku)}}</b-td>
                              <b-td>{{ $n(deepTube.kharif_2_aman)}}</b-td>
                              <b-td>{{ $n(deepTube.kharif_2_aman_per_hector_cost)}}</b-td>
                              <b-td>{{ $n(deepTube.kharif_2_others)}}</b-td>
                              <b-td>{{ $n(deepTube.kharif_2_aman + deepTube.kharif_2_others)}}</b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </b-col>
                    </b-row>
                    <b-row class="mb-1">
                      <b-col lg="12" sm="12">
                        <b-row>
                          <b-col class="text-right">{{ $t('irriPumpInformation.2nd_part') }}: </b-col>
                        </b-row>
                        <div>
                          <b-table-simple small bordered hover responsive="" class="mt-2">
                            <b-thead>
                              <b-tr>
                                <b-th colspan="14">{{ $t('irriPumpInformation.details_of_deep_tube_well_use_and_irrigation_area') }}</b-th>
                                <b-th rowspan="4">{{ $t('irriPumpInformation.total_irrigation_charge') }}</b-th>
                                <b-th rowspan="4">{{ $t('irriPumpInformation.total_cost') }}</b-th>
                                <b-th rowspan="4">{{ $t('irriPumpInformation.net_income') }}</b-th>
                                <b-th colspan="3">{{ $t('irriPumpInformation.beneficial_farmer') }}</b-th>
                                <b-th rowspan="4" style="width:10%">{{ $t('irriPumpInformation.comments') }}</b-th>
                              </b-tr>
                              <b-tr>
                                <b-th colspan="14">{{ $t('irriPumpInformation.robi') }}</b-th>
                                <b-th rowspan="3">{{ $t('irriPumpInformation.actual') }}</b-th>
                                <b-th rowspan="3">{{ $t('irriPumpInformation.barga') }}</b-th>
                                <b-th rowspan="3">{{ $t('irriPumpInformation.total') }}</b-th>
                              </b-tr>
                              <b-tr>
                                <b-th colspan="2">{{ $t('irriPumpInformation.borou_hector') }}</b-th>
                                <b-th colspan="2">{{ $t('irriPumpInformation.wheat_hector') }}</b-th>
                                <b-th colspan="2">{{ $t('irriPumpInformation.potato_hector') }}</b-th>
                                <b-th colspan="2">{{ $t('irriPumpInformation.corn_hector') }}</b-th>
                                <b-th colspan="2">{{ $t('irriPumpInformation.mustard_hector') }}</b-th>
                                <b-th colspan="2">{{ $t('irriPumpInformation.lentils_hector') }}</b-th>
                                <b-th>{{ $t('globalTrans.other') }}</b-th>
                                <b-th rowspan="2">
                                  {{ $t('irriPumpInformation.total_irrigation_area') }}
                                  <br/>
                                  {{ $n(31) }} = {{ $n(23) }} + {{ $n(25) }} + {{ $n(27) }} + {{ $n(29) }} + {{ $n(31) }} + {{ $n(35) }}
                                </b-th>
                              </b-tr>
                              <b-tr>
                                <b-th>{{ $t('irriPumpInformation.sech_area_hector') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.irrigation_cost_per_hectare') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.sech_area_hector') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.irrigation_cost_per_hectare') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.sech_area_hector') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.irrigation_cost_per_hectare') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.sech_area_hector') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.irrigation_cost_per_hectare') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.sech_area_hector') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.irrigation_cost_per_hectare') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.sech_area_hector') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.irrigation_cost_per_hectare') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.sech_area_hector') }}</b-th>
                              </b-tr>
                            </b-thead>
                            <b-tbody>
                              <b-tr>
                                <b-td>{{ $n(22) }}</b-td>
                                <b-td>{{ $n(23) }}</b-td>
                                <b-td>{{ $n(26) }}</b-td>
                                <b-td>{{ $n(27) }}</b-td>
                                <b-td>{{ $n(28) }}</b-td>
                                <b-td>{{ $n(29) }}</b-td>
                                <b-td>{{ $n(30) }}</b-td>
                                <b-td>{{ $n(31) }}</b-td>
                                <b-td>{{ $n(32) }}</b-td>
                                <b-td>{{ $n(33) }}</b-td>
                                <b-td>{{ $n(34) }}</b-td>
                                <b-td>{{ $n(35) }}</b-td>
                                <b-td>{{ $n(36) }}</b-td>
                                <b-td>{{ $n(37) }}</b-td>
                                <b-td>{{ $n(38) }}</b-td>
                                <b-td>{{ $n(39) }}</b-td>
                                <b-td>{{ $n(40) }}</b-td>
                                <b-td>{{ $n(41) }}</b-td>
                                <b-td>{{ $n(42) }}</b-td>
                                <b-td>{{ $n(43) }}</b-td>
                                <b-td>{{ $n(44) }}</b-td>
                              </b-tr>
                              <b-tr v-for="(deepTube, index) in deepTubwell" :key="index">
                                <b-td>{{ $n(deepTube.borou) }}</b-td>
                                <b-td>{{ $n(deepTube.borou_per_hector_cost) }}</b-td>
                                <b-td>{{ $n(deepTube.wheat) }}</b-td>
                                <b-td>{{ $n(deepTube.wheat_per_hector_cost) }}</b-td>
                                <b-td>{{ $n(deepTube.potato) }}</b-td>
                                <b-td>{{ $n(deepTube.potato_per_hector_cost) }}</b-td>
                                <b-td>{{ $n(deepTube.corn) }}</b-td>
                                <b-td>{{ $n(deepTube.corn_per_hector_cost) }}</b-td>
                                <b-td>{{ $n(deepTube.mustard) }}</b-td>
                                <b-td>{{ $n(deepTube.mustard_per_hector_cost) }}</b-td>
                                <b-td>{{ $n(deepTube.lentils) }}</b-td>
                                <b-td>{{ $n(deepTube.lentils_per_hector_cost) }}</b-td>
                                <b-td>{{ $n(deepTube.other_scheme_area) }}</b-td>
                                <b-td>{{ $n(deepTube.borou + deepTube.wheat + deepTube.potato + deepTube.corn + deepTube.corn + deepTube.mustard + deepTube.lentils + deepTube.other_scheme_area) }}</b-td>
                                <b-td>{{ $n(deepTube.collected_irri_payment) }}</b-td>
                                <b-td>
                                  {{
                                    $n(deepTube.borou_per_hector_cost + deepTube.wheat_per_hector_cost + deepTube.potato_per_hector_cost + deepTube.mustard_per_hector_cost + deepTube.lentils_per_hector_cost)
                                  }}
                                </b-td>
                                <b-td>
                                  {{
                                    $n((deepTube.collected_irri_payment)-(deepTube.borou_per_hector_cost + deepTube.wheat_per_hector_cost + deepTube.potato_per_hector_cost + deepTube.mustard_per_hector_cost + deepTube.lentils_per_hector_cost))
                                  }}
                                </b-td>
                                <b-td>{{ $n(deepTube.actual) }}</b-td>
                                <b-td>{{ $n(deepTube.barga) }}</b-td>
                                <b-td>{{ $n(deepTube.beneficial_farmer_total) }}</b-td>
                                <b-td>{{ deepTube.comments }}</b-td>
                              </b-tr>
                            </b-tbody>
                          </b-table-simple>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="mb-1">
                      <b-col lg="4" sm="4" class="text-center">
                        {{ $t('irriPumpInformation.gh_footer_1') }}
                        <div class="col-12"></div>
                        {{ $t('irriPumpInformation.bmda') }} ............. {{ $t('irriPumpInformation.Zone') }}
                      </b-col>
                      <b-col lg="4" sm="4" class="text-center">
                        {{ $t('irriPumpInformation.gh_footer_2') }}
                        <div class="col-12"></div>
                        {{ $t('irriPumpInformation.bmda') }} ............. {{ $t('irriPumpInformation.Zone') }}
                      </b-col>
                      <b-col lg="4" sm="4" class="text-center">
                        {{ $t('irriPumpInformation.gh_footer_3') }}
                        <div class="col-12"></div>
                        {{ $t('irriPumpInformation.bmda') }} ............. {{ $t('irriPumpInformation.Zone') }}
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
        <!-- <pre>{{deepTubwell}}</pre> -->
      </b-row>
    </b-container>
</template>
<script>
export default {
  name: 'FormLayout',
  props: ['id', 'item', 'search'],
  created () {
    this.deepTubwell = this.item
  },
  data () {
    return {
      deepTubwell: '',
      today: new Date().toDateString(),
      slOffset: 1
    }
  },
  computed: {
    location_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('org_pro.organization'), val: this.deepTubwell.org_name_bn, key1: this.$t('irriPumpInformation.pump_id'), val1: this.$n(this.deepTubwell.pump_id) },
          { key: this.$t('complain.division'), val: this.deepTubwell.division_name_bn, key1: this.$t('complain.district'), val1: this.deepTubwell.district_name_bn },
          { key: this.$t('complain.upazila'), val: this.deepTubwell.upazilla_name_bn, key1: this.$t('complain.union'), val1: this.deepTubwell.union_name_bn },
          { key: this.$t('irriPumpInformation.mouza_no'), val: this.$n(this.deepTubwell.mouza_no, { useGrouping: false }), key1: this.$t('irriPumpInformation.jl_no'), val1: this.$n(this.deepTubwell.jl_no, { useGrouping: false }) },
          { key: this.$t('irriPumpInformation.plot_no'), val: this.$n(this.deepTubwell.plot_no, { useGrouping: false }) }
        ]
      } else {
          return [
            { key: this.$t('org_pro.organization'), val: this.deepTubwell.org_name, key1: this.$t('irriPumpInformation.pump_id'), val1: this.deepTubwell.pump_id },
            { key: this.$t('complain.division'), val: this.deepTubwell.division_name, key1: this.$t('complain.district'), val1: this.deepTubwell.district_name },
            { key: this.$t('complain.upazila'), val: this.deepTubwell.upazilla_name, key1: this.$t('complain.union'), val1: this.deepTubwell.union_name },
            { key: this.$t('irriPumpInformation.mouza_no'), val: this.deepTubwell.mouza_no, key1: this.$t('irriPumpInformation.jl_no'), val1: this.deepTubwell.jl_no },
            { key: this.$t('irriPumpInformation.plot_no'), val: this.deepTubwell.plot_no }
          ]
      }
    },
    installation_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.date_of_excavation'), val: this.$d(new Date(this.deepTubwell.install_date_of_excavation)), key1: this.$t('irriPumpInformation.date_of_commission'), val1: this.$d(new Date(this.deepTubwell.install_date_of_commission)), key2: this.$t('irriPumpInformation.accepted_participation'), val2: this.$n(this.deepTubwell.install_accepted_participation) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.date_of_excavation'), val: this.$d(new Date(this.deepTubwell.install_date_of_excavation)), key1: this.$t('irriPumpInformation.date_of_commission'), val1: this.$d(new Date(this.deepTubwell.install_date_of_commission)), key2: this.$t('irriPumpInformation.accepted_participation'), val2: this.$n(this.deepTubwell.install_accepted_participation) }
          ]
      }
    },
    rehabilitation_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.date_of_excavation'), val: this.$d(new Date(this.deepTubwell.rehab_date_of_excavation)), key1: this.$t('irriPumpInformation.date_of_commission'), val1: this.$d(new Date(this.deepTubwell.rehab_date_of_commission)), key2: this.$t('irriPumpInformation.accepted_participation'), val2: this.$n(this.deepTubwell.rehab_accepted_participation) },
          { key: this.$t('irriPumpInformation.project_name'), val: this.deepTubwell.project_name, key1: this.$t('irriPumpInformation.deep_tube_well_nature'), val1: this.deepTubwell.deep_tube_well_nature, key2: this.$t('irriPumpInformation.operator_name'), val2: this.deepTubwell.operator_name }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.date_of_excavation'), val: this.$d(new Date(this.deepTubwell.rehab_date_of_excavation)), key1: this.$t('irriPumpInformation.date_of_commission'), val1: this.$d(new Date(this.deepTubwell.rehab_date_of_commission)), key2: this.$t('irriPumpInformation.accepted_participation'), val2: this.$n(this.deepTubwell.rehab_accepted_participation) },
          { key: this.$t('irriPumpInformation.project_name'), val: this.deepTubwell.project_name, key1: this.$t('irriPumpInformation.deep_tube_well_nature'), val1: this.deepTubwell.deep_tube_well_nature, key2: this.$t('irriPumpInformation.operator_name'), val2: this.deepTubwell.operator_name }
          ]
      }
    },
    housing_pipe_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.ms'), val: this.$n(this.deepTubwell.ms), key1: this.$t('irriPumpInformation.fg'), val1: this.$n(this.deepTubwell.fg), key2: this.$t('irriPumpInformation.updc'), val2: this.$n(this.deepTubwell.updc) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.ms'), val: this.$n(this.deepTubwell.ms), key1: this.$t('irriPumpInformation.fg'), val1: this.$n(this.deepTubwell.fg), key2: this.$t('irriPumpInformation.updc'), val2: this.$n(this.deepTubwell.updc) }
          ]
      }
    },
    filter_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.filter_ms'), val: this.$n(this.deepTubwell.filter_ms), key1: this.$t('irriPumpInformation.filter_fg'), val1: this.$n(this.deepTubwell.filter_fg), key2: this.$t('irriPumpInformation.updc'), val2: this.$n(this.deepTubwell.filter_updc) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.filter_ms'), val: this.$n(this.deepTubwell.filter_ms), key1: this.$t('irriPumpInformation.filter_fg'), val1: this.$n(this.deepTubwell.filter_fg), key2: this.$t('irriPumpInformation.updc'), val2: this.$n(this.deepTubwell.filter_updc) }
          ]
      }
    },
    installed_column_pipe_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.dia'), val: this.$n(this.deepTubwell.dia), key1: this.$t('irriPumpInformation.amount'), val1: this.$n(this.deepTubwell.amount), key2: '', val2: '' }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.dia'), val: this.$n(this.deepTubwell.dia), key1: this.$t('irriPumpInformation.amount'), val1: this.$n(this.deepTubwell.amount), key2: '', val2: '' }
          ]
      }
    },
    vertical_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.make_o_model'), val: this.deepTubwell.vertical_make_o_model, key1: this.$t('irriPumpInformation.unit_consumption'), val1: this.$n(this.deepTubwell.vertical_unit_consumption), key2: this.$t('irriPumpInformation.power'), val2: this.$n(this.deepTubwell.vertical_power) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.make_o_model'), val: this.deepTubwell.vertical_make_o_model, key1: this.$t('irriPumpInformation.unit_consumption'), val1: this.$n(this.deepTubwell.vertical_unit_consumption), key2: this.$t('irriPumpInformation.power'), val2: this.$n(this.deepTubwell.vertical_power) }
          ]
      }
    },
    turbine_submersible_motor_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.make_o_model'), val: this.deepTubwell.turbine_make_o_model, key1: this.$t('irriPumpInformation.unit_consumption'), val1: this.$n(this.deepTubwell.turbine_unit_consumption), key2: this.$t('irriPumpInformation.power'), val2: this.$n(this.deepTubwell.turbine_power) },
          { key: this.$t('irriPumpInformation.head'), val: this.$n(this.deepTubwell.head), key1: this.$t('irriPumpInformation.discharge'), val1: this.$n(this.deepTubwell.discharge), key2: this.$t('irriPumpInformation.command_area'), val2: this.$n(this.deepTubwell.command_area) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.make_o_model'), val: this.deepTubwell.turbine_make_o_model, key1: this.$t('irriPumpInformation.unit_consumption'), val1: this.$n(this.deepTubwell.turbine_unit_consumption), key2: this.$t('irriPumpInformation.power'), val2: this.$n(this.deepTubwell.turbine_power) },
          { key: this.$t('irriPumpInformation.head'), val: this.$n(this.deepTubwell.head), key1: this.$t('irriPumpInformation.discharge'), val1: this.$n(this.deepTubwell.discharge), key2: this.$t('irriPumpInformation.command_area'), val2: this.$n(this.deepTubwell.command_area) }
          ]
      }
    },
    kharif_1_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.aus'), val: this.$n(this.deepTubwell.kharif_1_aus), key1: this.$t('irriPumpInformation.others'), val1: this.$n(this.deepTubwell.kharif_1_others), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.kharif_1_total) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.aus'), val: this.$n(this.deepTubwell.kharif_1_aus), key1: this.$t('irriPumpInformation.others'), val1: this.$n(this.deepTubwell.kharif_1_others), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.kharif_1_total) }
          ]
      }
    },
    kharif_2_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.aman'), val: this.$n(this.deepTubwell.kharif_2_aman), key1: this.$t('irriPumpInformation.others'), val1: this.$n(this.deepTubwell.kharif_2_others), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.kharif_2_total) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.aman'), val: this.$n(this.deepTubwell.kharif_2_aman), key1: this.$t('irriPumpInformation.others'), val1: this.$n(this.deepTubwell.kharif_2_others), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.kharif_2_total) }
          ]
      }
    },
    robi_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.borou'), val: this.$n(this.deepTubwell.borou), key1: this.$t('irriPumpInformation.mustard'), val1: this.$n(this.deepTubwell.mustard), key2: this.$t('irriPumpInformation.wheat'), val2: this.$n(this.deepTubwell.wheat) },
          { key: this.$t('irriPumpInformation.lentils'), val: this.$n(this.deepTubwell.lentils), key1: this.$t('irriPumpInformation.potato'), val1: this.$n(this.deepTubwell.potato), key2: this.$t('irriPumpInformation.vegetables'), val2: this.$n(this.deepTubwell.vegetables) },
          { key: this.$t('irriPumpInformation.corn'), val: this.$n(this.deepTubwell.corn), key1: this.$t('irriPumpInformation.total'), val1: this.$n(this.deepTubwell.robi_total) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.borou'), val: this.$n(this.deepTubwell.borou), key1: this.$t('irriPumpInformation.mustard'), val1: this.$n(this.deepTubwell.mustard), key2: this.$t('irriPumpInformation.wheat'), val2: this.$n(this.deepTubwell.wheat) },
          { key: this.$t('irriPumpInformation.lentils'), val: this.$n(this.deepTubwell.lentils), key1: this.$t('irriPumpInformation.potato'), val1: this.$n(this.deepTubwell.potato), key2: this.$t('irriPumpInformation.vegetables'), val2: this.$n(this.deepTubwell.vegetables) },
          { key: this.$t('irriPumpInformation.corn'), val: this.$n(this.deepTubwell.corn), key1: this.$t('irriPumpInformation.total'), val1: this.$n(this.deepTubwell.robi_total) }
          ]
      }
    },
    beneficial_farmer_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.actual'), val: this.$n(this.deepTubwell.actual), key1: this.$t('irriPumpInformation.barga'), val1: this.$n(this.deepTubwell.barga), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.beneficial_farmer_total) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.actual'), val: this.$n(this.deepTubwell.actual), key1: this.$t('irriPumpInformation.barga'), val1: this.$n(this.deepTubwell.barga), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.beneficial_farmer_total) }
          ]
      }
    },
    tot_uses_unit_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.start_reading'), val: this.$n(this.deepTubwell.start_reading), key1: this.$t('irriPumpInformation.end_reading'), val1: this.$n(this.deepTubwell.end_reading), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.total_uses_unit) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.start_reading'), val: this.$n(this.deepTubwell.start_reading), key1: this.$t('irriPumpInformation.end_reading'), val1: this.$n(this.deepTubwell.end_reading), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.total_uses_unit) }
          ]
      }
    },
    irri_adai_descrip_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.hourly_used_unit'), val: this.$n(this.deepTubwell.hourly_used_unit), key1: this.$t('irriPumpInformation.tot_active_hour'), val1: this.$n(this.deepTubwell.total_active_hour), key2: this.$t('irriPumpInformation.hourly_irri_charge'), val2: this.$n(this.deepTubwell.hourly_irri_charge) },
          { key: this.$t('irriPumpInformation.recoverable_irri_payment'), val: this.$n(this.deepTubwell.recoverable_irri_payment), key1: this.$t('irriPumpInformation.collected_irri_payment'), val1: this.$n(this.deepTubwell.collected_irri_payment), key2: this.$t('irriPumpInformation.unpaid_money'), val2: this.$n(this.deepTubwell.unpaid_money) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.hourly_used_unit'), val: this.$n(this.deepTubwell.hourly_used_unit), key1: this.$t('irriPumpInformation.total_active_hour'), val1: this.$n(this.deepTubwell.total_active_hour), key2: this.$t('irriPumpInformation.hourly_irri_charge'), val2: this.$n(this.deepTubwell.hourly_irri_charge) },
          { key: this.$t('irriPumpInformation.recoverable_irri_payment'), val: this.$n(this.deepTubwell.recoverable_irri_payment), key1: this.$t('irriPumpInformation.collected_irri_payment'), val1: this.$n(this.deepTubwell.collected_irri_payment), key2: this.$t('irriPumpInformation.unpaid_money'), val2: this.$n(this.deepTubwell.unpaid_money) }
          ]
      }
    },
    mnge_ment_cost_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.tot_biddut_cost'), val: this.$n(this.deepTubwell.total_electricity_cost), key1: this.$t('irriPumpInformation.operator_salary'), val1: this.$n(this.deepTubwell.operator_salary), key2: this.$t('irriPumpInformation.maintance_cost'), val2: this.$n(this.deepTubwell.maintance_cost) },
          { key: this.$t('irriPumpInformation.other_cost'), val: this.$n(this.deepTubwell.other_cost), key1: this.$t('irriPumpInformation.tot_cost'), val1: this.$n(this.deepTubwell.total_cost), key2: this.$t('irriPumpInformation.tot_income'), val2: this.$n(this.deepTubwell.total_income) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.tot_biddut_cost'), val: this.$n(this.deepTubwell.total_electricity_cost), key1: this.$t('irriPumpInformation.operator_salary'), val1: this.$n(this.deepTubwell.operator_salary), key2: this.$t('irriPumpInformation.maintance_cost'), val2: this.$n(this.deepTubwell.maintance_cost) },
          { key: this.$t('irriPumpInformation.other_cost'), val: this.$n(this.deepTubwell.other_cost), key1: this.$t('irriPumpInformation.tot_cost'), val1: this.$n(this.deepTubwell.total_cost), key2: this.$t('irriPumpInformation.tot_income'), val2: this.$n(this.deepTubwell.total_income) }
          ]
      }
    },
    mnge_ment_cost_bottom_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.ganku_details'), val: this.deepTubwell.ganku_details },
          { key: this.$t('irriPumpInformation.super_v_mechanic'), val: this.deepTubwell.super_v_mechanic },
          { key: this.$t('irriPumpInformation.super_v_higher_engr'), val: this.deepTubwell.super_v_higher_engr },
          { key: this.$t('irriPumpInformation.comments'), val: this.deepTubwell.comments }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.ganku_details'), val: this.deepTubwell.ganku_details },
          { key: this.$t('irriPumpInformation.super_v_mechanic'), val: this.deepTubwell.super_v_mechanic },
          { key: this.$t('irriPumpInformation.super_v_higher_engr'), val: this.deepTubwell.super_v_higher_engr },
          { key: this.$t('irriPumpInformation.comments'), val: this.deepTubwell.comments }
          ]
      }
    }
  }
}
</script>
<style lang="scss">
  .details_report {
    font-size: 8px;
    color: #000;
    h5 {
      color: green !important;
      font-size: 12px !important;
    }
    .table-bordered td:nth-child(2n+1) {
      font-weight: 700;
    }
    .table-bordered th {
      text-align: center;
      font-weight: 900 !important;
      vertical-align: middle !important;
    }
    .table-bordered tr td {
      text-align: center;
      vertical-align: middle !important;
    }
  }
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
</style>
