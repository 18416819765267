<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row class="details_ga">
                  <b-col lg="12" sm="12">
                    <b-row class="mb-1">
                      <b-col lg="12" sm="12">
                        <div>
                            <b-button class="btn-primary btn-sm m-2" @click="pdfExport">
                              <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
                            </b-button>
                            <p class="text-right">{{ $t('irriPumpInformation.table_ga') }}: </p>
                            <h5 class="font-weight-bold text-capitalize text-center p-1"><u>{{ $t('irriPumpInformation.headingbmdb1') }}</u></h5>
                            <h5 class="font-weight-bold text-capitalize text-center p-1"><u>{{ $t('irriPumpInformation.headingbmdb2') }}</u></h5>
                            <!-- <b-row>
                                <b-col><p class="text-left">{{ $t('irriPumpInformation.region_name') }}: </p></b-col>
                                <b-col><p class="text-right">{{ $t('irriPumpInformation.table_kha') }}: </p></b-col>
                            </b-row> -->
                            <!-- <b-table striped small bordered hover :items="location_columns"  thead-class="hidden_header"></b-table> -->
                            <b-table-simple small bordered hover responsive="" class="mt-3">
                                <b-tr>
                                    <b-th rowspan="4">{{ $t('globalTrans.sl_no') }}</b-th>
                                    <b-th rowspan="4">{{ $t('globalTrans.region') }}</b-th>
                                    <b-th rowspan="4">{{ $t('irriPumpInformation.upazila') }}</b-th>
                                    <b-th rowspan="4">{{ $t('irriPumpInformation.total_plantable_area_of_Upazila') }}</b-th>
                                    <b-th colspan="27">{{ $t('irriPumpInformation.scheme_machine_description_of_the_use') }}</b-th>
                                </b-tr>
                                <b-tr>
                                    <b-th colspan="6">{{ $t('irriPumpInformation.bmda') }}</b-th>
                                    <b-th colspan="9">{{ $t('irriPumpInformation.badc') }}</b-th>
                                    <b-th colspan="12">{{ $t('irriPumpInformation.other_organization') }}</b-th>
                                </b-tr>
                                <b-tr>
                                    <b-th colspan="3">{{ $t('irriPumpInformation.dtw') }}</b-th>
                                    <b-th colspan="3">{{ $t('irriPumpInformation.llp') }}</b-th>
                                    <b-th colspan="3">{{ $t('irriPumpInformation.dtw') }}</b-th>
                                    <b-th colspan="3">{{ $t('irriPumpInformation.stw') }}</b-th>
                                    <b-th colspan="3">{{ $t('irriPumpInformation.llp') }}</b-th>
                                    <b-th colspan="3">{{ $t('irriPumpInformation.dtw') }}</b-th>
                                    <b-th colspan="3">{{ $t('irriPumpInformation.mini_dtw') }}</b-th>
                                    <b-th colspan="3">{{ $t('irriPumpInformation.stw') }}</b-th>
                                    <b-th colspan="3">{{ $t('irriPumpInformation.llp') }}</b-th>
                                </b-tr>
                                <b-tr>
                                    <b-th>{{ $t('irriPumpInformation.number') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.descarge_total_k_w') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.area_h') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.number') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.descarge_total_k_w') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.area_h') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.number') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.descarge_total_k_w') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.area_h') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.number') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.descarge_total_k_w') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.area_h') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.number') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.descarge_total_k_w') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.area_h') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.number') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.descarge_total_k_w') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.area_h') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.number') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.descarge_total_k_w') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.area_h') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.number') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.descarge_total_k_w') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.area_h') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.number') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.descarge_total_k_w') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.area_h') }}</b-th>
                                </b-tr>
                                <b-tr>
                                    <b-th>{{ $n(1) }}</b-th>
                                    <b-th>{{ $n(2) }}</b-th>
                                    <b-th>{{ $n(3) }}</b-th>
                                    <b-th>{{ $n(4) }}</b-th>
                                    <b-th>{{ $n(5) }}</b-th>
                                    <b-th>{{ $n(6) }}</b-th>
                                    <b-th>{{ $n(7) }}</b-th>
                                    <b-th>{{ $n(8) }}</b-th>
                                    <b-th>{{ $n(9) }}</b-th>
                                    <b-th>{{ $n(10) }}</b-th>
                                    <b-th>{{ $n(11) }}</b-th>
                                    <b-th>{{ $n(12) }}</b-th>
                                    <b-th>{{ $n(13) }}</b-th>
                                    <b-th>{{ $n(14) }}</b-th>
                                    <b-th>{{ $n(15) }}</b-th>
                                    <b-th>{{ $n(16) }}</b-th>
                                    <b-th>{{ $n(17) }}</b-th>
                                    <b-th>{{ $n(18) }}</b-th>
                                    <b-th>{{ $n(19) }}</b-th>
                                    <b-th>{{ $n(20) }}</b-th>
                                    <b-th>{{ $n(21) }}</b-th>
                                    <b-th>{{ $n(22) }}</b-th>
                                    <b-th>{{ $n(23) }}</b-th>
                                    <b-th>{{ $n(24) }}</b-th>
                                    <b-th>{{ $n(25) }}</b-th>
                                    <b-th>{{ $n(26) }}</b-th>
                                    <b-th>{{ $n(27) }}</b-th>
                                    <b-th>{{ $n(28) }}</b-th>
                                    <b-th>{{ $n(29) }}</b-th>
                                    <b-th>{{ $n(30) }}</b-th>
                                    <b-th>{{ $n(31) }}</b-th>
                                </b-tr>
                                <b-tr v-for="(deepTube, index) in deepTubwell" :key="index">
                                    <td>{{ $n(index+1) }}</td>
                                    <td>{{ ($i18n.locale==='bn') ? deepTube.office_name_bn  :  deepTube.office_name }}</td>
                                    <td>{{ ($i18n.locale==='bn') ? deepTube.upazilla_name_bn  :  deepTube.upazilla_name }}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </b-tr>
                            </b-table-simple>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="mb-1 mt-3">
                      <b-col lg="12" sm="12">
                        <div>
                            <b-table-simple small bordered hover responsive="">
                                <b-tr>
                                    <b-th colspan="24">{{ $t('irriPumpInformation.scheme_machine_description_of_the_use') }}</b-th>
                                    <b-th rowspan="4">{{ $t('irriPumpInformation.total_irrigated_area_h') }}</b-th>
                                    <b-th rowspan="4">{{ $t('irriPumpInformation.total_Uncultivated_area_h') }}</b-th>
                                </b-tr>
                                <b-tr>
                                    <b-th colspan="12">{{ $t('irriPumpInformation.personal_Initiative') }}</b-th>
                                    <b-th colspan="12">{{ $t('irriPumpInformation.grand_total') }}</b-th>
                                </b-tr>
                                <b-tr>
                                    <b-th colspan="3">{{ $t('irriPumpInformation.dtw') }}</b-th>
                                    <b-th colspan="3">{{ $t('irriPumpInformation.mini_dtw') }}</b-th>
                                    <b-th colspan="3">{{ $t('irriPumpInformation.stw') }}</b-th>
                                    <b-th colspan="3">{{ $t('irriPumpInformation.llp') }}</b-th>
                                    <b-th colspan="3">{{ $t('irriPumpInformation.dtw') }}</b-th>
                                    <b-th colspan="3">{{ $t('irriPumpInformation.mini_dtw') }}</b-th>
                                    <b-th colspan="3">{{ $t('irriPumpInformation.stw') }}</b-th>
                                    <b-th colspan="3">{{ $t('irriPumpInformation.llp') }}</b-th>
                                </b-tr>
                                <b-tr>
                                    <b-th>{{ $t('irriPumpInformation.number') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.descarge_total_k_w') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.area_h') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.number') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.descarge_total_k_w') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.area_h') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.number') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.descarge_total_k_w') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.area_h') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.number') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.descarge_total_k_w') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.area_h') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.number') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.descarge_total_k_w') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.area_h') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.number') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.descarge_total_k_w') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.area_h') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.number') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.descarge_total_k_w') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.area_h') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.number') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.descarge_total_k_w') }}</b-th>
                                    <b-th>{{ $t('irriPumpInformation.area_h') }}</b-th>
                                </b-tr>
                                <b-tr>
                                    <b-th>{{ $n(32) }}</b-th>
                                    <b-th>{{ $n(33) }}</b-th>
                                    <b-th>{{ $n(34) }}</b-th>
                                    <b-th>{{ $n(35) }}</b-th>
                                    <b-th>{{ $n(36) }}</b-th>
                                    <b-th>{{ $n(37) }}</b-th>
                                    <b-th>{{ $n(38) }}</b-th>
                                    <b-th>{{ $n(39) }}</b-th>
                                    <b-th>{{ $n(40) }}</b-th>
                                    <b-th>{{ $n(41) }}</b-th>
                                    <b-th>{{ $n(42) }}</b-th>
                                    <b-th>{{ $n(43) }}</b-th>
                                    <b-th>{{ $n(44) }}</b-th>
                                    <b-th>{{ $n(45) }}</b-th>
                                    <b-th>{{ $n(46) }}</b-th>
                                    <b-th>{{ $n(47) }}</b-th>
                                    <b-th>{{ $n(48) }}</b-th>
                                    <b-th>{{ $n(49) }}</b-th>
                                    <b-th>{{ $n(50) }}</b-th>
                                    <b-th>{{ $n(51) }}</b-th>
                                    <b-th>{{ $n(52) }}</b-th>
                                    <b-th>{{ $n(53) }}</b-th>
                                    <b-th>{{ $n(54) }}</b-th>
                                    <b-th>{{ $n(55) }}</b-th>
                                    <b-th>{{ $n(56) }}</b-th>
                                    <b-th>{{ $n(57) }}</b-th>
                                </b-tr>
                                <b-tr v-for="(deepTube, index1) in deepTubwell" :key="index1">
                                    <b-td></b-td>
                                    <b-td></b-td>
                                    <b-td></b-td>
                                    <b-td></b-td>
                                    <b-td></b-td>
                                    <b-td></b-td>
                                    <b-td></b-td>
                                    <b-td></b-td>
                                    <b-td></b-td>
                                    <b-td></b-td>
                                    <b-td></b-td>
                                    <b-td></b-td>
                                    <b-td></b-td>
                                    <b-td></b-td>
                                    <b-td></b-td>
                                    <b-td></b-td>
                                    <b-td></b-td>
                                    <b-td></b-td>
                                    <b-td></b-td>
                                    <b-td></b-td>
                                    <b-td></b-td>
                                    <b-td></b-td>
                                    <b-td></b-td>
                                    <b-td></b-td>
                                    <b-td></b-td>
                                    <b-td></b-td>
                                </b-tr>
                            </b-table-simple>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="mb-1 mt-5">
                      <!-- <b-col lg="12" sm="12" style="height:70px">
                        N.B . {{ $t('irriPumpInformation.fixture_design') }}
                      </b-col> -->
                      <b-col lg="4" sm="4" class="text-center">
                        {{ $t('irriPumpInformation.gh_footer_1') }}
                        <div class="col-12"></div>
                        {{ $t('irriPumpInformation.bmda') }} ............. {{ $t('irriPumpInformation.Zone') }}
                      </b-col>
                      <b-col lg="4" sm="4" class="text-center">
                        {{ $t('irriPumpInformation.gh_footer_2') }}
                        <div class="col-12"></div>
                        {{ $t('irriPumpInformation.bmda') }} ............. {{ $t('irriPumpInformation.Zone') }}
                      </b-col>
                      <b-col lg="4" sm="4" class="text-center">
                        {{ $t('irriPumpInformation.gh_footer_3') }}
                        <div class="col-12"></div>
                        {{ $t('irriPumpInformation.bmda') }} ............. {{ $t('irriPumpInformation.Zone') }}
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import ExportPdf from '@/Utils/export-pdf'

export default {
  name: 'FormLayout',
  props: ['id', 'item'],
  created () {
    this.deepTubwell = this.item
  },
  data () {
    return {
      deepTubwell: [],
      slOffset: 1
    }
  },
  computed: {
  },
  methods: {
    pdfExport () {
      const rowData = this.getPdfData()
      this.deepTubwell.map(item => {
          this.orgSingleId = item.org_id
      })
      const extraData = {
        totalHeaderRows: 5
      }

      const labels = [
          { text: this.$t('globalTrans.sl_no'), rowSpan: 4, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$t('irriPumpInformation.region'), rowSpan: 4, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$t('globalTrans.upazila'), rowSpan: 4, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$t('irriPumpInformation.total_plantable_area_of_Upazila'), rowSpan: 4, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$t('irriPumpInformation.scheme_machine_description_of_the_use'), colSpan: 27, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {}
      ]
      const labels1 = [
          {},
          {},
          {},
          {},
          { text: this.$t('irriPumpInformation.bmda'), colSpan: 6, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          {},
          {},
          {},
          {},
          {},
          { text: this.$t('irriPumpInformation.badc'), colSpan: 9, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          { text: this.$t('irriPumpInformation.other_organization'), colSpan: 12, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {}
      ]
      const labels2 = [
          {},
          {},
          {},
          {},
          { text: this.$t('irriPumpInformation.dtw'), colSpan: 3, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          {},
          {},
          { text: this.$t('irriPumpInformation.llp'), colSpan: 3, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          {},
          {},
          { text: this.$t('irriPumpInformation.dtw'), colSpan: 3, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          {},
          {},
          { text: this.$t('irriPumpInformation.stw'), colSpan: 3, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          {},
          {},
          { text: this.$t('irriPumpInformation.llp'), colSpan: 3, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          {},
          {},
          { text: this.$t('irriPumpInformation.dtw'), colSpan: 3, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          {},
          {},
          { text: this.$t('irriPumpInformation.mini_dtw'), colSpan: 3, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          {},
          {},
          { text: this.$t('irriPumpInformation.stw'), colSpan: 3, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          {},
          {},
          { text: this.$t('irriPumpInformation.llp'), colSpan: 3, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          {},
          {}
      ]
      const labels3 = [
            {},
            {},
            {},
            {},
            { text: this.$t('irriPumpInformation.number'), style: 'tableHeader', alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
            { text: this.$t('irriPumpInformation.descarge_total_k_w'), style: 'tableHeader', alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
            { text: this.$t('irriPumpInformation.area_h'), style: 'tableHeader', alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
            { text: this.$t('irriPumpInformation.number'), style: 'tableHeader', alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
            { text: this.$t('irriPumpInformation.descarge_total_k_w'), style: 'tableHeader', alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
            { text: this.$t('irriPumpInformation.area_h'), style: 'tableHeader', alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
            { text: this.$t('irriPumpInformation.number'), style: 'tableHeader', alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
            { text: this.$t('irriPumpInformation.descarge_total_k_w'), style: 'tableHeader', alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
            { text: this.$t('irriPumpInformation.area_h'), style: 'tableHeader', alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
            { text: this.$t('irriPumpInformation.number'), style: 'tableHeader', alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
            { text: this.$t('irriPumpInformation.descarge_total_k_w'), style: 'tableHeader', alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
            { text: this.$t('irriPumpInformation.area_h'), style: 'tableHeader', alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
            { text: this.$t('irriPumpInformation.number'), style: 'tableHeader', alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
            { text: this.$t('irriPumpInformation.descarge_total_k_w'), style: 'tableHeader', alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
            { text: this.$t('irriPumpInformation.area_h'), style: 'tableHeader', alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
            { text: this.$t('irriPumpInformation.number'), style: 'tableHeader', alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
            { text: this.$t('irriPumpInformation.descarge_total_k_w'), style: 'tableHeader', alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
            { text: this.$t('irriPumpInformation.area_h'), style: 'tableHeader', alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
            { text: this.$t('irriPumpInformation.number'), style: 'tableHeader', alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
            { text: this.$t('irriPumpInformation.descarge_total_k_w'), style: 'tableHeader', alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
            { text: this.$t('irriPumpInformation.area_h'), style: 'tableHeader', alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
            { text: this.$t('irriPumpInformation.number'), style: 'tableHeader', alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
            { text: this.$t('irriPumpInformation.descarge_total_k_w'), style: 'tableHeader', alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
            { text: this.$t('irriPumpInformation.area_h'), style: 'tableHeader', alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
            { text: this.$t('irriPumpInformation.number'), style: 'tableHeader', alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
            { text: this.$t('irriPumpInformation.descarge_total_k_w'), style: 'tableHeader', alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
            { text: this.$t('irriPumpInformation.area_h'), style: 'tableHeader', alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) }
      ]
      const labels4 = [
          { text: this.$n(1), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(2), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(3), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(4), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(5), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(6), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(7), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(8), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(9), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(10), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(11), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(12), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(13), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(14), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(15), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(16), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(17), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(18), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(19), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(20), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(21), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(22), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(23), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(24), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(25), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(26), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(27), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(28), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(29), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(30), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { text: this.$n(31), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) }
      ]
      rowData.unshift(labels, labels1, labels2, labels3, labels4)
      const reportTitle = this.$t('irriPumpInformation.headingbmdb1')
      const reportSubTitle = this.$t('irriPumpInformation.headingbmdb2')
      const reportFormTitle = this.$t('irriPumpInformation.table_ga')
      const columnWids = ['*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*']
      ExportPdf.exportPdfMultipleHeader(irriSchemeServiceBaseUrl, '/report-heading/detail', this.orgSingleId, reportTitle, reportSubTitle, reportFormTitle, rowData, columnWids, extraData)
    },
    getPdfData () {
      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial_no', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { key: 'office_name_bn' },
          { key: 'upazilla_name_bn' },
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {}
        ]
      } else {
        keys = [
          { key: 'serial_no', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) },
          { key: 'office_name' },
          { key: 'upazilla_name' },
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {}
        ]
      }
      var serial = 0
      const listData = this.deepTubwell.map((item, key) => {
          serial += 1
          const rowData = keys.map(keyItem => {
            if (keyItem.key === 'serial_no') {
                return { text: this.$n(serial), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) }
            }
            if (keyItem.key === 'office_name_bn') {
                return { text: item.office_name_bn, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) }
            }
            if (keyItem.key === 'office_name') {
                return { text: item.office_name, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) }
            }
            if (keyItem.key === 'upazilla_name_bn') {
                return { text: item.upazilla_name_bn, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) }
            }
            if (keyItem.key === 'upazilla_name') {
                return { text: item.upazilla_name, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 6 : 5) }
            }
            return { text: item[keyItem.key] }
        })

        return rowData
      })

      return listData
    }
  }
}
</script>
<style lang="scss">
  .details_ga {
    font-size: 8px;
    color: #000;
    h5 {
      color: green !important;
      font-size: 12px !important;
    }
    .table-bordered td:nth-child(2n+1) {
      font-weight: 700;
    }
    .table-bordered th {
      text-align: center;
      font-weight: 900 !important;
      vertical-align: middle !important;
    }
    .table-bordered tr td {
      text-align: center;
    }
  }
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
</style>
