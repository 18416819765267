<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col class="text-right">
                    <b-button class="btn-primary btn-sm m-2" @click="pdfExport()">
                      <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-row class="details_report">
                  <b-col lg="12" sm="12">
                    <b-row class="mb-1">
                      <b-col lg="12" sm="12">
                        <div>
                          <h5 class="font-weight-bold text-capitalize text-center p-1"><u>{{ $t('irriPumpInformation.report_kha_title') }}</u></h5>
                          <b-row>
                            <!-- <b-col><p class="text-left">{{ $t('irriPumpInformation.region_name') }}: </p></b-col> -->
                            <b-col><p class="text-right">{{ $t('irriPumpInformation.table_kha') }}: </p></b-col>
                          </b-row>
                          <b-table-simple small bordered hover responsive>
                            <b-thead class="bg-light">
                              <b-tr>
                                <b-th rowspan="4">{{ $t('globalTrans.sl_no') }}</b-th>
                                <b-th rowspan="4">{{ $t('irriPumpInformation.region_name') }}</b-th>
                                <b-th rowspan="4">{{ $t('globalTrans.upazila') }}</b-th>
                                <b-th rowspan="4">{{ $t('irriPumpInformation.totol_installed_llp') }}</b-th>
                                <b-th rowspan="4">{{ $t('irriPumpInformation.totol_commisioned_llp') }}</b-th>
                                <b-th rowspan="4">{{ $t('irriPumpInformation.totol_used_llp') }}</b-th>
                                <b-th rowspan="4">{{ $t('irriPumpInformation.upazila_average_conjumption') }}</b-th>
                                <b-th colspan="10">{{ $t('irriPumpInformation.uses_llp_scheme_description') }}</b-th>
                              </b-tr>
                              <b-tr>
                                <b-th colspan="5">{{ $t('irriPumpInformation.kharif_1') }}</b-th>
                                <b-th colspan="5">{{ $t('irriPumpInformation.kharif_2') }}</b-th>
                              </b-tr>
                              <b-tr>
                                <b-th rowspan="2">{{ $t('irriPumpInformation.llp_number') }}</b-th>
                                <b-th colspan="2">{{ $t('irriPumpInformation.t_aous') }}</b-th>
                                <b-th rowspan="2">{{ $t('irriPumpInformation.other_scheme_area') }}</b-th>
                                <b-th rowspan="2">{{ $t('irriPumpInformation.total_scheme_area_kharif_1') }}</b-th>
                                <b-th rowspan="2">{{ $t('irriPumpInformation.llp_number') }}</b-th>
                                <b-th colspan="2">{{ $t('irriPumpInformation.t_aman') }}</b-th>
                                <b-th rowspan="2">{{ $t('irriPumpInformation.other_scheme_area') }}</b-th>
                                <b-th rowspan="2">{{ $t('irriPumpInformation.total_scheme_area_kharif_2') }}</b-th>
                              </b-tr>
                              <b-tr>
                                <b-th>{{ $t('irriPumpInformation.scheme_area') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.scheme_cost_per_hector') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.scheme_area') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.scheme_cost_per_hector') }}</b-th>
                              </b-tr>
                              <b-tr>
                                <b-th>{{ $n(1) }}</b-th>
                                <b-th>{{ $n(2) }}</b-th>
                                <b-th>{{ $n(3) }}</b-th>
                                <b-th>{{ $n(4) }}</b-th>
                                <b-th>{{ $n(5) }}</b-th>
                                <b-th>{{ $n(6) }}</b-th>
                                <b-th>{{ $n(7) }}</b-th>
                                <b-th>{{ $n(8) }}</b-th>
                                <b-th>{{ $n(9) }}</b-th>
                                <b-th>{{ $n(10) }}</b-th>
                                <b-th>{{ $n(11) }}</b-th>
                                <b-th>{{ $n(12) }}</b-th>
                                <b-th>{{ $n(13) }}</b-th>
                                <b-th>{{ $n(14) }}</b-th>
                                <b-th>{{ $n(15) }}</b-th>
                                <b-th>{{ $n(16) }}</b-th>
                                <b-th>{{ $n(17) }}</b-th>
                              </b-tr>
                            </b-thead>
                            <b-tbody>
                              <b-tr v-for="(deepTubwell, index) in deepTubwells" :key="index">
                                <td>{{ $n(index+1) }}</td>
                                <td>{{ $i18n.locale === 'bn' ? deepTubwell.office_name_bn : deepTubwell.office_name }}</td>
                                <td>{{ $i18n.locale === 'bn' ? deepTubwell.upazilla_name_bn : deepTubwell.upazilla_name }}</td>
                                <td>{{ $n(deepTubwell.install_accepted_participation) }}</td>
                                <td>{{ $n(deepTubwell.rehab_accepted_participation) }}</td>
                                <td>{{ $n(deepTubwell.install_accepted_participation + deepTubwell.rehab_accepted_participation) }}</td>
                                <td>{{ $n((deepTubwell.vertical_unit_consumption + deepTubwell.turbine_unit_consumption)/(index+1)) }}</td>
                                <td>{{ $n(deepTubwell.kharif_1_llp) }}</td>
                                <td>{{ $n(deepTubwell.kharif_1_aus) }}</td>
                                <td>{{ $n(deepTubwell.kharif_1_aus_per_hector_cost) }}</td>
                                <td>{{ $n(deepTubwell.kharif_1_others) }}</td>
                                <td>{{ $n(deepTubwell.kharif_1_aus + deepTubwell.kharif_1_others) }}</td>
                                <td>{{ $n(deepTubwell.kharif_2_llp) }}</td>
                                <td>{{ $n(deepTubwell.kharif_2_aman) }}</td>
                                <td>{{ $n(deepTubwell.kharif_2_aman_per_hector_cost) }}</td>
                                <td>{{ $n(deepTubwell.kharif_2_others) }}</td>
                                <td>{{ $n(deepTubwell.kharif_2_aman + deepTubwell.kharif_2_others) }}</td>
                              </b-tr>
                            </b-tbody>
                          </b-table-simple>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="mb-1">
                      <b-col lg="12" sm="12">
                        <div>
                          <b-table-simple small bordered hover responsive>
                            <b-thead class="bg-light">
                              <b-tr>
                                <b-th colspan="15">{{ $t('irriPumpInformation.uses_llp_scheme_description') }}</b-th>
                                <b-th rowspan="4">{{ $t('irriPumpInformation.total_collected_scheme_charge') }}</b-th>
                                <b-th colspan="3">{{ $t('irriPumpInformation.mnge_ment_cost') }}</b-th>
                                <b-th rowspan="4">{{ $t('irriPumpInformation.net_income_37') }}</b-th>
                                <b-th colspan="3">{{ $t('irriPumpInformation.beneficial_farmer') }}</b-th>
                                <b-th rowspan="4">{{ $t('irriPumpInformation.comments') }}</b-th>
                              </b-tr>
                              <b-tr>
                                <b-th colspan="15">{{ $t('irriPumpInformation.robi') }}</b-th>
                                <b-th rowspan="3">{{ $t('irriPumpInformation.for_llp') }}</b-th>
                                <b-th rowspan="3">{{ $t('irriPumpInformation.pontoon_llp') }}</b-th>
                                <b-th rowspan="3">{{ $t('irriPumpInformation.total_expense_36') }}</b-th>
                                <b-th rowspan="3">{{ $t('irriPumpInformation.actual') }}</b-th>
                                <b-th rowspan="3">{{ $t('irriPumpInformation.barga') }}</b-th>
                                <b-th rowspan="3">{{ $t('irriPumpInformation.total') }}</b-th>
                              </b-tr>
                              <b-tr>
                                <b-th rowspan="2">{{ $t('irriPumpInformation.llp_number') }}</b-th>
                                <b-th colspan="2">{{ $t('irriPumpInformation.borou_hector') }}</b-th>
                                <b-th colspan="2">{{ $t('irriPumpInformation.wheat_hector') }}</b-th>
                                <b-th colspan="2">{{ $t('irriPumpInformation.potato_hector') }}</b-th>
                                <b-th colspan="2">{{ $t('irriPumpInformation.corn_hector') }}</b-th>
                                <b-th colspan="2">{{ $t('irriPumpInformation.mustard_hector') }}</b-th>
                                <b-th colspan="2">{{ $t('irriPumpInformation.lentils_hector') }}</b-th>
                                <b-th rowspan="2">{{ $t('irriPumpInformation.other_scheme_area') }}</b-th>
                                <b-th rowspan="2">{{ $t('irriPumpInformation.total_scheme_area_robi') }}</b-th>
                              </b-tr>
                              <b-tr>
                                <b-th>{{ $t('irriPumpInformation.scheme_area') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.scheme_cost_per_hector') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.scheme_area') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.scheme_cost_per_hector') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.scheme_area') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.scheme_cost_per_hector') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.scheme_area') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.scheme_cost_per_hector') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.scheme_area') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.scheme_cost_per_hector') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.scheme_area') }}</b-th>
                                <b-th>{{ $t('irriPumpInformation.scheme_cost_per_hector') }}</b-th>
                              </b-tr>
                              <b-tr>
                                <b-th>{{ $n(18) }}</b-th>
                                <b-th>{{ $n(19) }}</b-th>
                                <b-th>{{ $n(20) }}</b-th>
                                <b-th>{{ $n(21) }}</b-th>
                                <b-th>{{ $n(22) }}</b-th>
                                <b-th>{{ $n(23) }}</b-th>
                                <b-th>{{ $n(24) }}</b-th>
                                <b-th>{{ $n(25) }}</b-th>
                                <b-th>{{ $n(26) }}</b-th>
                                <b-th>{{ $n(27) }}</b-th>
                                <b-th>{{ $n(28) }}</b-th>
                                <b-th>{{ $n(29) }}</b-th>
                                <b-th>{{ $n(30) }}</b-th>
                                <b-th>{{ $n(31) }}</b-th>
                                <b-th>{{ $n(32) }}</b-th>
                                <b-th>{{ $n(33) }}</b-th>
                                <b-th>{{ $n(34) }}</b-th>
                                <b-th>{{ $n(35) }}</b-th>
                                <b-th>{{ $n(36) }}</b-th>
                                <b-th>{{ $n(37) }}</b-th>
                                <b-th>{{ $n(38) }}</b-th>
                                <b-th>{{ $n(39) }}</b-th>
                                <b-th>{{ $n(40) }}</b-th>
                                <b-th>{{ $n(41) }}</b-th>
                              </b-tr>
                            </b-thead>
                            <b-tbody>
                              <b-tr v-for="(deepTubwell, index) in deepTubwells" :key="index">
                                <td>{{ $n(deepTubwell.robi_llp) }}</td>
                                <td>{{ $n(deepTubwell.borou) }}</td>
                                <td>{{ $n(deepTubwell.borou_per_hector_cost) }}</td>
                                <td>{{ $n(deepTubwell.wheat) }}</td>
                                <td>{{ $n(deepTubwell.wheat_per_hector_cost) }}</td>
                                <td>{{ $n(deepTubwell.potato) }}</td>
                                <td>{{ $n(deepTubwell.potato_per_hector_cost) }}</td>
                                <td>{{ $n(deepTubwell.corn) }}</td>
                                <td>{{ $n(deepTubwell.corn_per_hector_cost) }}</td>
                                <td>{{ $n(deepTubwell.mustard) }}</td>
                                <td>{{ $n(deepTubwell.mustard_per_hector_cost) }}</td>
                                <td>{{ $n(deepTubwell.lentils) }}</td>
                                <td>{{ $n(deepTubwell.lentils_per_hector_cost) }}</td>
                                <td>{{ $n(deepTubwell.other_scheme_area) }}</td>
                                <td>{{ $n(deepTubwell.borou + deepTubwell.wheat + deepTubwell.potato + deepTubwell.corn + deepTubwell.mustard + deepTubwell.lentils) }}</td>
                                <td>{{ $n(deepTubwell.collected_irri_payment) }}</td>
                                <td>{{ $n(deepTubwell.llp_expense) }}</td>
                                <td>{{ $n(deepTubwell.pontoon_expense) }}</td>
                                <td>{{ $n(deepTubwell.llp_expense + deepTubwell.pontoon_expense) }}</td>
                                <td>{{ $n( deepTubwell.collected_irri_payment - (deepTubwell.llp_expense + deepTubwell.pontoon_expense)) }}</td>
                                <td>{{ $n(deepTubwell.actual) }}</td>
                                <td>{{ $n(deepTubwell.barga) }}</td>
                                <td>{{ $n(deepTubwell.actual + deepTubwell.barga) }}</td>
                                <td>{{ deepTubwell.comments }}</td>
                              </b-tr>
                            </b-tbody>
                          </b-table-simple>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="mb-1 mt-5">
                      <b-col lg="4" sm="4" class="text-center">
                        {{ $t('irriPumpInformation.gh_footer_1') }}
                        <div class="col-12"></div>
                        {{ $t('irriPumpInformation.bmda') }} ............. {{ $t('irriPumpInformation.Zone') }}
                      </b-col>
                      <b-col lg="4" sm="4" class="text-center">
                        {{ $t('irriPumpInformation.gh_footer_2') }}
                        <div class="col-12"></div>
                        {{ $t('irriPumpInformation.bmda') }} ............. {{ $t('irriPumpInformation.Zone') }}
                      </b-col>
                      <b-col lg="4" sm="4" class="text-center">
                        {{ $t('irriPumpInformation.gh_footer_3') }}
                        <div class="col-12"></div>
                        {{ $t('irriPumpInformation.bmda') }} ............. {{ $t('irriPumpInformation.Zone') }}
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import ExportPdf from '@/Utils/export-pdf'
export default {
  name: 'FormLayout',
  props: ['id', 'item'],
  created () {
    this.deepTubwells = this.item
  },
  data () {
    return {
      deepTubwells: [],
      net_income: 0,
      orgSingleId: 0
    }
  },
  computed: {
  },
  methods: {
    pdfExport () {
      const rowData = this.getPdfData1()
      const rowData2 = this.getPdfData2()
      this.deepTubwells.map(item => {
          this.orgSingleId = item.org_id
      })
      // const header = [
      //     { text: this.$t('warehouseFarmer.warehouse_name'), fontSize: (this.$i18n.locale === 'bn' ? 12 : 10) }, { text: ': ' }, { text: 'warehouse' },
      //     { text: this.$t('warehouseFarmer.date'), fontSize: (this.$i18n.locale === 'bn' ? 12 : 10) }, { text: ': ' }, { text: 'date' },
      //     { text: this.$t('warehouseFarmer.father_name'), fontSize: (this.$i18n.locale === 'bn' ? 12 : 10) }, { text: ': ' }, { text: 'father' },
      //     { text: this.$t('warehouseFarmer.district'), fontSize: (this.$i18n.locale === 'bn' ? 12 : 10) }, { text: ': ' }, { text: 'district' },
      //     { text: this.$t('warehouseFarmer.today_items'), fontSize: (this.$i18n.locale === 'bn' ? 12 : 10) }, { text: ': ' }, { text: 'item' }, { text: this.$t('warehouseFarmer.submitToWare'), fontSize: (this.$i18n.locale === 'bn' ? 12 : 10) }
      // ]
      // const footerData = this.$t('irriPumpInformation.bmda')
      const labels = [
          { text: this.$t('globalTrans.sl_no'), rowSpan: 4, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$t('irriPumpInformation.region_name'), rowSpan: 4, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$t('globalTrans.upazila'), rowSpan: 4, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$t('irriPumpInformation.totol_installed_llp'), rowSpan: 4, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$t('irriPumpInformation.totol_commisioned_llp'), rowSpan: 4, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$t('irriPumpInformation.totol_used_llp'), rowSpan: 4, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$t('irriPumpInformation.upazila_average_conjumption'), rowSpan: 4, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$t('irriPumpInformation.uses_llp_scheme_description'), colSpan: 10, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {}
      ]
      const labels1 = [
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          { text: this.$t('irriPumpInformation.kharif_1'), colSpan: 5, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          {},
          {},
          {},
          {},
          { text: this.$t('irriPumpInformation.kharif_2'), colSpan: 5, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          {},
          {},
          {},
          {}
      ]
      const labels2 = [
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          { text: this.$t('irriPumpInformation.llp_number'), rowSpan: 2, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$t('irriPumpInformation.t_aous'), colSpan: 2, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          {},
          { text: this.$t('irriPumpInformation.other_scheme_area'), rowSpan: 2, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$t('irriPumpInformation.total_scheme_area_kharif_1'), rowSpan: 2, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$t('irriPumpInformation.llp_number'), rowSpan: 2, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$t('irriPumpInformation.t_aman'), colSpan: 2, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          {},
          { text: this.$t('irriPumpInformation.other_scheme_area'), rowSpan: 2, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$t('irriPumpInformation.total_scheme_area_kharif_2'), rowSpan: 2, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) }
      ]
      const labels3 = [
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          { text: this.$t('irriPumpInformation.scheme_area'), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$t('irriPumpInformation.scheme_cost_per_hector'), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          {},
          {},
          {},
          { text: this.$t('irriPumpInformation.scheme_area'), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$t('irriPumpInformation.scheme_cost_per_hector'), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          {},
          {}
      ]
      const labels4 = [
          { text: this.$n(1), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$n(2), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$n(3), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$n(4), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$n(5), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$n(6), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$n(7), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$n(8), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$n(9), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$n(10), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$n(11), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$n(12), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$n(13), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$n(14), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$n(15), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$n(16), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) },
          { text: this.$n(17), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 7 : 6) }
      ]
      rowData.unshift(labels, labels1, labels2, labels3, labels4)
      rowData2.unshift(labels, labels1, labels2, labels3, labels4)
      // rowData2.unshift(labels, labels1, labels2, labels3, labels4)
      const extraData = {
        totalHeaderRows: 1
      }
      // const reportTitle = this.$i18n.locale === 'en' ? this.$t('irriPumpInformation.report_kha_title') : this.$t('irriPumpInformation.report_kha_title')
      const reportTitle = this.$t('irriPumpInformation.report_kha_title')
      const reportFormTitle = this.$t('irriPumpInformation.table_kha')
      const columnWids = ['3%', '10%', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*']
      ExportPdf.exportPdfMultipleHeader(irriSchemeServiceBaseUrl, '/report-heading/detail', this.orgSingleId, reportTitle, null, reportFormTitle, rowData, columnWids, extraData)
    },
    getPdfData1 () {
      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial_no' },
          { key: 'office_name_bn' },
          { key: 'upazilla_name_bn' },
          { key: 'install_accepted_participation' },
          { key: 'rehab_accepted_participation' },
          { key: 'total_used_llp' },
          { key: 'upazila_average_conjumption' },
          { key: 'kharif_1_llp' },
          { key: 'kharif_1_aus' },
          { key: 'kharif_1_aus_per_hector_cost' },
          { key: 'kharif_1_others' },
          { key: 'kharif_1_others_scheme_area_total' },
          { key: 'kharif_2_llp' },
          { key: 'kharif_2_aman' },
          { key: 'kharif_2_aman_per_hector_cost' },
          { key: 'kharif_2_others' },
          { key: 'kharif_2_others_area_total' }
        ]
      } else {
        keys = [
          { key: 'serial_no' },
          { key: 'office_name' },
          { key: 'upazilla_name' },
          { key: 'install_accepted_participation' },
          { key: 'rehab_accepted_participation' },
          { key: 'total_used_llp' },
          { key: 'upazila_average_conjumption' },
          { key: 'kharif_1_llp' },
          { key: 'kharif_1_aus' },
          { key: 'kharif_1_aus_per_hector_cost' },
          { key: 'kharif_1_others' },
          { key: 'kharif_1_others_scheme_area_total' },
          { key: 'kharif_2_llp' },
          { key: 'kharif_2_aman' },
          { key: 'kharif_2_aman_per_hector_cost' },
          { key: 'kharif_2_others' },
          { key: 'kharif_2_others_area_total' }
        ]
      }
      var serial = 0
      const listData = this.deepTubwells.map((item, key) => {
          serial += 1
          const rowData = keys.map(keyItem => {
            if (keyItem.key === 'serial_no') {
                return { text: this.$n(serial), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'office_name_bn') {
                return { text: item.office_name_bn, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'office_name') {
                return { text: item.office_name, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'upazilla_name_bn') {
                return { text: item.upazilla_name_bn, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'upazilla_name') {
                return { text: item.upazilla_name, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'install_accepted_participation') {
              return { text: this.$n(item.install_accepted_participation, { useGrouping: false }), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'rehab_accepted_participation') {
              return { text: this.$n(item.rehab_accepted_participation, { useGrouping: false }), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'total_used_llp') {
              return { text: this.$n(item.install_accepted_participation + item.rehab_accepted_participation, { useGrouping: false }), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'upazila_average_conjumption') {
              return { text: this.$n((item.vertical_unit_consumption + item.turbine_unit_consumption) / (key + 1), { useGrouping: false }), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'kharif_1_llp') {
              return { text: this.$n(item.kharif_1_llp, { useGrouping: false }), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'kharif_1_aus') {
              return { text: this.$n(item.kharif_1_aus, { useGrouping: false }), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'kharif_1_aus_per_hector_cost') {
              return { text: this.$n(item.kharif_1_aus_per_hector_cost, { useGrouping: false }), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'kharif_1_others') {
              return { text: this.$n(item.kharif_1_others, { useGrouping: false }), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'kharif_1_others_scheme_area_total') {
              return { text: this.$n(item.kharif_1_aus + item.kharif_1_others, { useGrouping: false }), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'kharif_2_llp') {
              return { text: this.$n(item.kharif_2_llp, { useGrouping: false }), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'kharif_2_aman') {
              return { text: this.$n(item.kharif_2_aman, { useGrouping: false }), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'kharif_2_aman_per_hector_cost') {
              return { text: this.$n(item.kharif_2_aman_per_hector_cost, { useGrouping: false }), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'kharif_2_others') {
              return { text: this.$n(item.kharif_2_others, { useGrouping: false }), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'kharif_2_others_area_total') {
              return { text: this.$n(item.kharif_2_aman + item.kharif_2_others, { useGrouping: false }), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            return { text: item[keyItem.key] }
        })

        return rowData
      })

      return listData
    },
    getPdfData2 () {
      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial_no' },
          { key: 'office_name_bn' },
          { key: 'upazilla_name_bn' },
          { key: 'install_accepted_participation' },
          { key: 'rehab_accepted_participation' },
          { key: 'total_used_llp' },
          { key: 'upazila_average_conjumption' },
          { key: 'kharif_1_llp' },
          { key: 'kharif_1_aus' },
          { key: 'kharif_1_aus_per_hector_cost' },
          { key: 'kharif_1_others' },
          { key: 'kharif_1_others_scheme_area_total' },
          { key: 'kharif_2_llp' },
          { key: 'kharif_2_aman' },
          { key: 'kharif_2_aman_per_hector_cost' },
          { key: 'kharif_2_others' },
          { key: 'kharif_2_others_area_total' }
        ]
      } else {
        keys = [
          { key: 'serial_no' },
          { key: 'office_name' },
          { key: 'upazilla_name' },
          { key: 'install_accepted_participation' },
          { key: 'rehab_accepted_participation' },
          { key: 'total_used_llp' },
          { key: 'upazila_average_conjumption' },
          { key: 'kharif_1_llp' },
          { key: 'kharif_1_aus' },
          { key: 'kharif_1_aus_per_hector_cost' },
          { key: 'kharif_1_others' },
          { key: 'kharif_1_others_scheme_area_total' },
          { key: 'kharif_2_llp' },
          { key: 'kharif_2_aman' },
          { key: 'kharif_2_aman_per_hector_cost' },
          { key: 'kharif_2_others' },
          { key: 'kharif_2_others_area_total' }
        ]
      }
      var serial = 0
      const listData = this.deepTubwells.map((item, key) => {
          serial += 1
          const rowData = keys.map(keyItem => {
            if (keyItem.key === 'serial_no') {
                return { text: this.$n(serial), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'office_name_bn') {
                return { text: item.office_name_bn, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'office_name') {
                return { text: item.office_name, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'upazilla_name_bn') {
                return { text: item.upazilla_name_bn, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'upazilla_name') {
                return { text: item.upazilla_name, alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'install_accepted_participation') {
              return { text: this.$n(item.install_accepted_participation, { useGrouping: false }), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'rehab_accepted_participation') {
              return { text: this.$n(item.rehab_accepted_participation, { useGrouping: false }), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'total_used_llp') {
              return { text: this.$n(item.install_accepted_participation + item.rehab_accepted_participation, { useGrouping: false }), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'upazila_average_conjumption') {
              return { text: this.$n((item.vertical_unit_consumption + item.turbine_unit_consumption) / (key + 1), { useGrouping: false }), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'kharif_1_llp') {
              return { text: this.$n(item.kharif_1_llp, { useGrouping: false }), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'kharif_1_aus') {
              return { text: this.$n(item.kharif_1_aus, { useGrouping: false }), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'kharif_1_aus_per_hector_cost') {
              return { text: this.$n(item.kharif_1_aus_per_hector_cost, { useGrouping: false }), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'kharif_1_others') {
              return { text: this.$n(item.kharif_1_others, { useGrouping: false }), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'kharif_1_others_scheme_area_total') {
              return { text: this.$n(item.kharif_1_aus + item.kharif_1_others, { useGrouping: false }), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'kharif_2_llp') {
              return { text: this.$n(item.kharif_2_llp, { useGrouping: false }), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'kharif_2_aman') {
              return { text: this.$n(item.kharif_2_aman, { useGrouping: false }), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'kharif_2_aman_per_hector_cost') {
              return { text: this.$n(item.kharif_2_aman_per_hector_cost, { useGrouping: false }), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'kharif_2_others') {
              return { text: this.$n(item.kharif_2_others, { useGrouping: false }), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            if (keyItem.key === 'kharif_2_others_area_total') {
              return { text: this.$n(item.kharif_2_aman + item.kharif_2_others, { useGrouping: false }), alignment: 'center', fontSize: (this.$i18n.locale === 'bn' ? 8 : 6) }
            }
            return { text: item[keyItem.key] }
        })

        return rowData
      })

      return listData
    }
  }
}
</script>
<style lang="scss">
  .details_report {
    font-size: 8px;
    color: #000;
    h5 {
      color: green !important;
      font-size: 12px !important;
    }
    .table-bordered td:nth-child(2n+1) {
      font-weight: 700;
    }
    .table-bordered th {
      text-align: center;
      font-weight: 900 !important;
    }
    .table-bordered tr td {
      text-align: center;
    }
  }
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
</style>
