<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('irriPumpInformation.bmda_report') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="division_id"
              >
              <template v-slot:label="">{{ $t('org_pro_division.division') }}</template>
              <b-form-select
                plain
                v-model="search.division_id"
                :options="divisionList"
                id="division_id"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="district_id"
              >
              <template v-slot:label="">{{ $t('org_pro_district.district') }}</template>
              <b-form-select
                plain
                v-model="search.district_id"
                :options="districtList"
                id="district_id"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="upazilla_id"
              >
              <template v-slot:label="">{{ $t('org_pro_upazilla.upazilla') }}</template>
              <b-form-select
                plain
                v-model="search.upazilla_id"
                :options="upazilaList"
                id="upazilla_id"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="report_type_id"
              >
              <template v-slot:label="">{{ $t('irriPumpInformation.report_type') }} <span class="text-danger">*</span></template>
              <b-form-select
                plain
                v-model="search.report_type_id"
                :options="reportTypeList"
                id="report_type_id"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="form_type_id"
              >
              <template v-slot:label="">{{ $t('irriPumpInformation.form_type') }} <span class="text-danger">*</span></template>
              <b-form-select
                plain
                v-model="search.form_type_id"
                :options="formTypeList"
                id="form_type_id"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row v-if="dataSearching">
      <b-col md="12" lg="12" xl="12">
        <iq-card class="text-center">
          <template v-slot:body>
            <b-overlay :show="loading">
              Loading...
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-detail-kha" size="xl" :title="detailTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <DetailModalKHA :id="editItemId" :key="editItemId" :item="item"/>
    </b-modal>
    <b-modal id="modal-detail-ga" size="xl" :title="detailTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <DetailModalGA :id="editItemId" :key="editItemId" :item="item"/>
    </b-modal>
    <b-modal id="modal-detail-gha" size="xl" :title="detailTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <DetailModalGHA :id="editItemId" :key="editItemId" :item="item" :search="search"/>
    </b-modal>
    <b-modal id="modal-detail-umo" size="xl" :title="detailTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <DetailModalUMO :id="editItemId" :key="editItemId" :item="item"/>
    </b-modal>
    <b-modal id="modal-detail-ko" size="xl" :title="detailTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <DetailModalKO :id="editItemId" :key="editItemId" :item="item"/>
    </b-modal>
  </b-container>
</template>
<script>
import DetailModalKHA from './DetailModalKHA'
import DetailModalGA from './DetailModalGA'
import DetailModalGHA from './DetailModalGHA'
import DetailModalUMO from './DetailModalUMO'
import DetailModalKO from './DetailModalKO'
import { deepTubeWellReport, pumpInformationToggleStatus } from '../../api/routes'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    DetailModalKHA, DetailModalGA, DetailModalGHA, DetailModalUMO, DetailModalKO
  },
  data () {
    return {
      search: {
        report_type_id: '0',
        form_type_id: '0',
        pump_id: '0',
        division_id: '0',
        district_id: '0',
        upazilla_id: '0',
        union_id: '0'
      },
      rows: [],
      item: [],
      pumpDropdownList: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      loading: false,
      dataSearching: false
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    formTitle () {
      return (this.testId === 0) ? this.$t('irriPumpInformation.pump_report_entry') : this.$t('irriPumpInformation.pump_report_entry') + ' ' + this.$t('globalTrans.modify')
    },
    detailTitle () {
      return this.$t('irriPumpInformation.bmda_report')
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
        { label: this.$t('org_pro.organization'), class: 'text-left' },
        { label: this.$t('irriPumpInformation.pump_id'), class: 'text-left' },
        { label: this.$t('org_pro_division.division'), class: 'text-left' },
        { label: this.$t('org_pro_district.district'), class: 'text-left' },
        { label: this.$t('org_pro_upazilla.upazilla'), class: 'text-left' },
        { label: this.$t('org_pro_union.union'), class: 'text-left' },
        { label: this.$t('globalTrans.status'), class: 'text-center' },
        { label: this.$t('globalTrans.action'), class: 'text-center' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_name_bn' },
          { key: 'pump_id' },
          { key: 'division_name_bn' },
          { key: 'district_name_bn' },
          { key: 'upazilla_name_bn' },
          { key: 'union_name_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_name' },
          { key: 'pump_id' },
           { key: 'division_name' },
           { key: 'district_name' },
          { key: 'upazilla_name' },
          { key: 'union_name' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    divisionList: function () {
      const divisions = this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
      return divisions.map((item) => {
          if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn !== 'undefined' ? item.text_bn : '' }
          } else {
              return { value: item.value, text: item.text_en !== 'undefined' ? item.text_en : '' }
          }
      })
    },
    reportTypeList: function () {
      const reportType = this.$store.state.commonObjCommonConfig.reportTypes
      return reportType.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text }
        }
      })
    },
    formTypeList: function () {
      const formType = this.$store.state.commonObjCommonConfig.formTypes
      return formType.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text }
        }
      })
    }
  },
  watch: {
    'search.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
    }
  },
  mounted () {
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 0) {
      this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id,
          division_id: this.authUser.office_detail.division_id,
          district_id: this.authUser.office_detail.district_id,
          upazilla_id: this.authUser.office_detail.upazilla_id
      })
    }
    this.loadData()
  },
  methods: {
    details (item) {
      this.editItemId = item.id
      this.item = item
    },
    searchData () {
      if (this.search.report_type_id !== '0' && this.search.form_type_id !== '0') {
        this.loadData()
      } else {
        alert(this.$t('irriPumpInformation.select_report_type_form_type'))
      }
    },
    remove (item) {
      this.changeStatus(irriSchemeServiceBaseUrl, pumpInformationToggleStatus, item)
    },
    loadData () {
      this.dataSearching = true
      this.loading = true
      RestApi.getData(irriSchemeServiceBaseUrl, deepTubeWellReport, this.search).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelationalData(response))
          if (this.search.form_type_id === 1) {
            this.$bvModal.show('modal-detail-ko')
          } else if (this.search.form_type_id === 2) {
            this.$bvModal.show('modal-detail-kha')
          } else if (this.search.form_type_id === 3) {
            this.$bvModal.show('modal-detail-ga')
          } else if (this.search.form_type_id === 4) {
            this.$bvModal.show('modal-detail-gha')
          } else if (this.search.form_type_id === 5) {
            this.$bvModal.show('modal-detail-umo')
          }
          this.item = this.listData
        } else {
          this.$store.dispatch('setList', [])
        }
        this.dataSearching = false
        this.loading = false
      })
    },
    getRelationalData (data) {
      const organizationList = this.$store.state.commonObj.organizationProfileList
      const divisionList = this.$store.state.commonObj.divisionList
      const districtList = this.$store.state.commonObj.districtList
      const upazilaList = this.$store.state.commonObj.upazilaList
      const unionList = this.$store.state.commonObj.unionList
      const officeList = this.$store.state.commonObj.officeList
      return data.data.map(item => {
        const orgObject = organizationList.find(oganization => oganization.value === item.org_id)
        const divisionObject = divisionList.find(division => division.value === item.division_id)
        const districtObject = districtList.find(district => district.value === item.district_id)
        const upazilaObject = upazilaList.find(upazilla => upazilla.value === item.upazilla_id)
        const unionObject = unionList.find(union => union.value === item.union_id)
        const officeObject = officeList.find(office => office.upazilla_id === item.upazilla_id)
        const orgData = {
          org_name: orgObject !== undefined ? orgObject.text_en : '',
          org_name_bn: orgObject !== undefined ? orgObject.text_bn : ''
        }
        const divisionData = {
          division_name: divisionObject !== undefined ? divisionObject.text_en : '',
          division_name_bn: divisionObject !== undefined ? divisionObject.text_bn : ''
        }
        const districtData = {
          district_name: districtObject !== undefined ? districtObject.text_en : '',
          district_name_bn: districtObject !== undefined ? districtObject.text_bn : ''
        }
        const upazillaData = {
          upazilla_name: upazilaObject !== undefined ? upazilaObject.text_en : '',
          upazilla_name_bn: upazilaObject !== undefined ? upazilaObject.text_bn : ''
        }
        const unionData = {
          union_name: unionObject !== undefined ? unionObject.text_en : '',
          union_name_bn: unionObject !== undefined ? unionObject.text_bn : ''
        }
        const officeData = {
          office_name: officeObject !== undefined ? officeObject.text_en : '',
          office_name_bn: officeObject !== undefined ? officeObject.text_bn : ''
        }
        return Object.assign({}, item, orgData, divisionData, districtData, upazillaData, unionData, officeData)
      })
    },
    getPumplist (orgId) {
        this.pumpInformationList = this.$store.state.IrriConfig.commonObj.pumpInfoList.filter(pumpInfo => pumpInfo.org_id === orgId && pumpInfo.status === 0)
        return this.pumpInformationList.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.id, text: obj.pump_id }
          } else {
            return { value: obj.id, text: obj.pump_id }
          }
        })
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }
      return unionList
    },
    getProjectlist (orgId) {
      this.projectList = this.$store.state.IrriConfig.commonObj.projectList.filter(project => project.org_id === orgId && project.status === 0)
    }
  }
}
</script>
