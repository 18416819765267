<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row class="details_report">
                  <b-col lg="12" sm="12">
                    <b-row class="mb-1">
                      <b-col lg="12" sm="12">
                        <div>
                          <h5 class="font-weight-bold text-capitalize text-center p-1"><u>{{ $t('irriPumpInformation.report_umo_title') }}</u></h5>
                          <b-row>
                            <b-col><p class="text-left">{{ $t('irriPumpInformation.region_name') }}: </p></b-col>
                            <b-col><p class="text-right">{{ $t('irriPumpInformation.table_umo') }}: </p></b-col>
                          </b-row>
                          <b-table-simple small bordered hover responsive>
                            <b-tr>
                              <b-th rowspan="2">{{ $t('globalTrans.sl_no') }}</b-th>
                              <b-th colspan="7">{{ $t('irriPumpInformation.LLP_position') }}</b-th>
                              <b-th colspan="5">{{ $t('irriPumpInformation.LLP_description') }}</b-th>
                              <b-th rowspan="2"> {{ $t('irriPumpInformation.unit_consumption') }}</b-th>
                              <b-th rowspan="2">{{ $t('irriPumpInformation.project_implementer_name') }}</b-th>
                              <b-th rowspan="2">{{ $t('irriPumpInformation.operator_name') }}</b-th>
                            </b-tr>
                            <b-tr>
                              <b-th>{{ $t('irriPumpInformation.region_name') }}</b-th>
                              <b-th>{{ $t('org_pro_upazilla.upazilla') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.pontu_name') }}</b-th>
                              <b-th>{{ $t('complain.union') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.mouza_no') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.jl_no') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.plot_no') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.install_date') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.commission_date') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.make_model') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.discharge') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.power_hp') }}</b-th>
                            </b-tr>
                            <b-tr>
                              <b-th>{{ $n(1) }}</b-th>
                              <b-th>{{ $n(2) }}</b-th>
                              <b-th>{{ $n(3) }}</b-th>
                              <b-th>{{ $n(4) }}</b-th>
                              <b-th>{{ $n(5) }}</b-th>
                              <b-th>{{ $n(6) }}</b-th>
                              <b-th>{{ $n(7) }}</b-th>
                              <b-th>{{ $n(8) }}</b-th>
                              <b-th>{{ $n(9) }}</b-th>
                              <b-th>{{ $n(10) }}</b-th>
                              <b-th>{{ $n(11) }}</b-th>
                              <b-th>{{ $n(12) }}</b-th>
                              <b-th>{{ $n(13) }}</b-th>
                              <b-th>{{ $n(14) }}</b-th>
                              <b-th>{{ $n(15) }}</b-th>
                              <b-th>{{ $n(16) }}</b-th>
                            </b-tr>
                            <b-tr  v-for="(deepTube, index) in deepTubwell" :key="index">
                            <b-td>{{index+1}}</b-td>
                              <td></td>
                              <td>{{ ($i18n.locale==='bn') ? deepTube.upazilla_name_bn  :  deepTube.upazilla_name }}</td>
                              <td></td>
                              <td>{{ ($i18n.locale==='bn') ? deepTube.union_name_bn  :  deepTube.union_name }}</td>
                              <td>{{ $n(deepTube.mouza_no) }}</td>
                              <td>{{ $n(deepTube.jl_no) }}</td>
                              <td>{{ $n(deepTube.plot_no) }}</td>
                              <td>{{ deepTube.install_date_of_excavation|dateFormat }}</td>
                              <td>{{ deepTube.date_of_commission }}</td>
                              <td>{{ deepTube.turbine_make_o_model }}</td>
                              <td>{{ $n(deepTube.discharge) }}</td>
                              <td>{{ $n(deepTube.turbine_power) }}</td>
                              <td>{{ $n(deepTube.turbine_unit_consumption) }}</td>
                              <td>{{ deepTube.project_name }}</td>
                              <td>{{ deepTube.operator_name }}</td>
                            </b-tr>
                          </b-table-simple>
                          <b-table-simple small bordered hover responsive>
                            <b-tr>
                              <b-th rowspan="2">{{ $t('globalTrans.sl_no') }}</b-th>
                              <b-th colspan="14">{{ $t('irriPumpInformation.amount_irrigated_land') }}</b-th>
                              <b-th colspan="3"> {{ $t('irriPumpInformation.beneficial_farmer') }}</b-th>
                              <b-th colspan="3">{{ $t('irriPumpInformation.tot_uses_unit') }}</b-th>
                            </b-tr>
                            <b-tr>
                              <b-th colspan="3">{{ $t('irriPumpInformation.kharif_1') }}</b-th>
                              <b-th colspan="3">{{ $t('irriPumpInformation.kharif_2') }}</b-th>
                              <b-th colspan="8">{{ $t('irriPumpInformation.robi') }}</b-th>
                              <b-th rowspan="2">{{ $t('irriPumpInformation.actual') }}</b-th>
                              <b-th rowspan="2">{{ $t('irriPumpInformation.barga') }}</b-th>
                              <b-th rowspan="2">{{ $t('irriPumpInformation.total') }}</b-th>
                              <b-th rowspan="2">{{ $t('irriPumpInformation.start_reading') }}</b-th>
                              <b-th rowspan="2">{{ $t('irriPumpInformation.end_reading') }}</b-th>
                              <b-th rowspan="2">{{ $t('irriPumpInformation.total') }}</b-th>
                            </b-tr>
                            <tr>
                              <b-th></b-th>
                              <b-th>{{ $t('irriPumpInformation.aus') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.others') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.total') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.aus') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.others') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.total') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.borou') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.wheat') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.potato') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.corn') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.mustard') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.lentils') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.vegetables') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.total') }}</b-th>
                            </tr>
                            <b-tr>
                              <b-th></b-th>
                              <b-th>{{ $n(17) }}</b-th>
                              <b-th>{{ $n(18) }}</b-th>
                              <b-th>{{ $n(19) }}</b-th>
                              <b-th>{{ $n(20) }}</b-th>
                              <b-th>{{ $n(21) }}</b-th>
                              <b-th>{{ $n(22) }}</b-th>
                              <b-th>{{ $n(23) }}</b-th>
                              <b-th>{{ $n(24) }}</b-th>
                              <b-th>{{ $n(25) }}</b-th>
                              <b-th>{{ $n(26) }}</b-th>
                              <b-th>{{ $n(27) }}</b-th>
                              <b-th>{{ $n(28) }}</b-th>
                              <b-th>{{ $n(29) }}</b-th>
                              <b-th>{{ $n(30) }}</b-th>
                              <b-th>{{ $n(31) }}</b-th>
                              <b-th>{{ $n(32) }}</b-th>
                              <b-th>{{ $n(33) }}</b-th>
                              <b-th>{{ $n(34) }}</b-th>
                              <b-th>{{ $n(35) }}</b-th>
                              <b-th>{{ $n(36) }}</b-th>
                            </b-tr>
                            <b-tr v-for="(deepTube, index) in deepTubwell" :key="index">
                              <td></td>
                              <td>{{ $n(deepTube.potato) }}</td>
                              <td>{{ $n(deepTube.other_cost) }}</td>
                              <td>{{ $n(deepTube.total_cost) }}</td>
                              <td>{{ $n(deepTube.potato) }}</td>
                              <td>{{ $n(deepTube.other_cost) }}</td>
                              <td>{{ $n(deepTube.total_cost) }}</td>
                              <td>{{ $n(deepTube.borou) }}</td>
                              <td>{{ $n(deepTube.wheat) }}</td>
                              <td>{{ $n(deepTube.potato) }}</td>
                              <td>{{ $n(deepTube.corn) }}</td>
                              <td>{{ $n(deepTube.mustard) }}</td>
                              <td>{{ $n(deepTube.lentils) }}</td>
                              <td>{{ $n(deepTube.vegetables) }}</td>
                              <td>{{ $n(deepTube.total_cost) }}</td>
                              <td>{{ $n(deepTube.actual) }}</td>
                              <td>{{ $n(deepTube.barga) }}</td>
                              <td>{{ $n(deepTube.total_cost) }}</td>
                              <td>{{ $n(deepTube.start_reading) }}</td>
                              <td>{{ $n(deepTube.start_reading) }}</td>
                              <td>{{ $n(deepTube.total_uses_unit) }}</td>
                            </b-tr>
                          </b-table-simple>
                           <b-table-simple small bordered hover responsive>
                            <b-tr>
                              <b-th colspan="6">{{ $t('irriPumpInformation.irri_adai_descrip') }}</b-th>
                              <b-th colspan="11">{{ $t('irriPumpInformation.mnge_ment_cost') }}</b-th>
                              <b-th rowspan="3">{{ $t('irriPumpInformation.net_income_57') }}</b-th>
                              <b-th rowspan="3">{{ $t('irriPumpInformation.ganku_details') }}</b-th>
                              <b-th rowspan="3">{{ $t('irriPumpInformation.super_v_mechanic') }}</b-th>
                              <b-th rowspan="3">{{ $t('irriPumpInformation.super_v_higher_engr') }}</b-th>
                              <b-th rowspan="3">{{ $t('irriPumpInformation.comments') }}</b-th>
                            </b-tr>
                            <b-tr>
                              <b-th rowspan="2">{{ $t('irriPumpInformation.hourly_used_unit') }}</b-th>
                              <b-th rowspan="2">{{ $t('irriPumpInformation.tot_active_hour') }}</b-th>
                              <b-th rowspan="2">{{ $t('irriPumpInformation.hourly_irri_charge') }}</b-th>
                              <b-th rowspan="2">{{ $t('irriPumpInformation.recoverable_irri_payment') }}</b-th>
                              <b-th rowspan="2">{{ $t('irriPumpInformation.collected_irri_payment') }}</b-th>
                              <b-th rowspan="2">{{ $t('irriPumpInformation.unpaid_money') }}</b-th>
                              <b-th colspan="5">{{ $t('irriPumpInformation.LLG') }}</b-th>
                              <b-th colspan="5">{{ $t('irriPumpInformation.pontuwari') }}</b-th>
                              <b-th rowspan="2">{{ $t('irriPumpInformation.total') }}</b-th>
                            </b-tr>
                            <tr>
                              <b-th>{{ $t('irriPumpInformation.tot_biddut_cost') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.operator_salary') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.maintance_cost') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.other_cost') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.tot_cost') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.tot_biddut_cost') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.operator_salary') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.maintance_cost') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.other_cost') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.tot_cost') }}</b-th>
                              <!-- <b-th>{{ $t('irriPumpInformation.corn') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.mustard') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.lentils') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.vegetables') }}</b-th>
                              <b-th>{{ $t('irriPumpInformation.total') }}</b-th> -->
                            </tr>
                            <b-tr>
                              <b-th>{{ $n(37) }}</b-th>
                              <b-th>{{ $n(38) }}</b-th>
                              <b-th>{{ $n(39) }}</b-th>
                              <b-th>{{ $n(40) }}</b-th>
                              <b-th>{{ $n(41) }}</b-th>
                              <b-th>{{ $n(42) }}</b-th>
                              <b-th>{{ $n(43) }}</b-th>
                              <b-th>{{ $n(44) }}</b-th>
                              <b-th>{{ $n(45) }}</b-th>
                              <b-th>{{ $n(46) }}</b-th>
                              <b-th>{{ $n(47) }}</b-th>
                              <b-th>{{ $n(48) }}</b-th>
                              <b-th>{{ $n(49) }}</b-th>
                              <b-th>{{ $n(50) }}</b-th>
                              <b-th>{{ $n(51) }}</b-th>
                              <b-th>{{ $n(52) }}</b-th>
                              <b-th>{{ $n(53) }}</b-th>
                              <b-th>{{ $n(54) }}</b-th>
                              <b-th>{{ $n(55) }}</b-th>
                              <b-th>{{ $n(56) }}</b-th>
                              <b-th>{{ $n(57) }}</b-th>
                              <b-th>{{ $n(58) }}</b-th>
                            </b-tr>
                            <b-tr v-for="(deepTube, index) in deepTubwell" :key="index">
                              <td>{{ $n(deepTube.hourly_used_unit) }}</td>
                              <td>{{ $n(deepTube.total_active_hour) }}</td>
                              <td>{{ $n(deepTube.hourly_irri_charge) }}</td>
                              <td>{{ $n(deepTube.recoverable_irri_payment) }}</td>
                              <td>{{ $n(deepTube.collected_irri_payment) }}</td>
                              <td>{{ $n(deepTube.unpaid_money) }}</td>
                              <td>{{ $n(deepTube.total_electricity_cost) }}</td>
                              <td>{{ $n(deepTube.operator_salary) }}</td>
                              <td>{{ $n(deepTube.maintance_cost) }}</td>
                              <td>{{ $n(deepTube.other_cost) }}</td>
                              <td>{{ $n(deepTube.total_cost) }}</td>
                              <td>{{ $n(deepTube.total_electricity_cost) }}</td>
                              <td>{{ $n(deepTube.operator_salary) }}</td>
                              <td>{{ $n(deepTube.maintance_cost) }}</td>
                              <td>{{ $n(deepTube.other_cost) }}</td>
                              <td>{{ $n(deepTube.total_cost) }}</td>
                              <td>{{ $n(deepTube.total_cost) }}</td>
                              <td>{{ $n(deepTube.total_income) }}</td>
                              <td>{{ deepTube.ganku_details }}</td>
                              <td>{{ deepTube.super_v_mechanic }}</td>
                              <td>{{ deepTube.super_v_higher_engr }}</td>
                              <td>{{ deepTube.comments }}</td>
                            </b-tr>
                          </b-table-simple>
                        </div>
                      </b-col>
                    </b-row>
                    <!-- <b-row class="mb-1">
                      <b-col lg="12" sm="12">
                        <div>
                          <b-table striped small bordered hover :items="installation_columns"  thead-class="hidden_header"></b-table>
                        </div>
                      </b-col>
                    </b-row> -->
                      <b-row class="mb-1 mt-5">
                      <!-- <b-col lg="12" sm="12" style="height:70px">
                        N.B . {{ $t('irriPumpInformation.fixture_design') }}
                      </b-col> -->
                      <b-col lg="4" sm="4" class="text-center">
                        {{ $t('irriPumpInformation.gh_footer_1') }}
                        <div class="col-12"></div>
                        {{ $t('irriPumpInformation.bmda') }} ............. {{ $t('irriPumpInformation.Zone') }}
                      </b-col>
                      <b-col lg="4" sm="4" class="text-center">
                        {{ $t('irriPumpInformation.gh_footer_2') }}
                        <div class="col-12"></div>
                        {{ $t('irriPumpInformation.bmda') }} ............. {{ $t('irriPumpInformation.Zone') }}
                      </b-col>
                      <b-col lg="4" sm="4" class="text-center">
                        {{ $t('irriPumpInformation.gh_footer_3') }}
                        <div class="col-12"></div>
                        {{ $t('irriPumpInformation.bmda') }} ............. {{ $t('irriPumpInformation.Zone') }}
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
export default {
  name: 'FormLayout',
  props: ['id', 'item'],
  created () {
    this.deepTubwell = this.item
  },
  data () {
    return {
      deepTubwell: '',
      slOffset: 1
    }
  },
  computed: {
    location_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('org_pro.organization'), val: this.deepTubwell.org_name_bn, key1: this.$t('irriPumpInformation.pump_id'), val1: this.$n(this.deepTubwell.pump_id) },
          { key: this.$t('complain.division'), val: this.deepTubwell.division_name_bn, key1: this.$t('complain.district'), val1: this.deepTubwell.district_name_bn },
          { key: this.$t('complain.upazila'), val: this.deepTubwell.upazilla_name_bn, key1: this.$t('complain.union'), val1: this.deepTubwell.union_name_bn },
          { key: this.$t('irriPumpInformation.mouza_no'), val: this.$n(this.deepTubwell.mouza_no, { useGrouping: false }), key1: this.$t('irriPumpInformation.jl_no'), val1: this.$n(this.deepTubwell.jl_no, { useGrouping: false }) },
          { key: this.$t('irriPumpInformation.plot_no'), val: this.$n(this.deepTubwell.plot_no, { useGrouping: false }) }
        ]
      } else {
          return [
            { key: this.$t('org_pro.organization'), val: this.deepTubwell.org_name, key1: this.$t('irriPumpInformation.pump_id'), val1: this.deepTubwell.pump_id },
            { key: this.$t('complain.division'), val: this.deepTubwell.division_name, key1: this.$t('complain.district'), val1: this.deepTubwell.district_name },
            { key: this.$t('complain.upazila'), val: this.deepTubwell.upazilla_name, key1: this.$t('complain.union'), val1: this.deepTubwell.union_name },
            { key: this.$t('irriPumpInformation.mouza_no'), val: this.deepTubwell.mouza_no, key1: this.$t('irriPumpInformation.jl_no'), val1: this.deepTubwell.jl_no },
            { key: this.$t('irriPumpInformation.plot_no'), val: this.deepTubwell.plot_no }
          ]
      }
    },
    installation_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.date_of_excavation'), val: this.$d(new Date(this.deepTubwell.install_date_of_excavation)), key1: this.$t('irriPumpInformation.date_of_commission'), val1: this.$d(new Date(this.deepTubwell.install_date_of_commission)), key2: this.$t('irriPumpInformation.accepted_participation'), val2: this.$n(this.deepTubwell.install_accepted_participation) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.date_of_excavation'), val: this.$d(new Date(this.deepTubwell.install_date_of_excavation)), key1: this.$t('irriPumpInformation.date_of_commission'), val1: this.$d(new Date(this.deepTubwell.install_date_of_commission)), key2: this.$t('irriPumpInformation.accepted_participation'), val2: this.$n(this.deepTubwell.install_accepted_participation) }
          ]
      }
    },
    rehabilitation_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.date_of_excavation'), val: this.$d(new Date(this.deepTubwell.rehab_date_of_excavation)), key1: this.$t('irriPumpInformation.date_of_commission'), val1: this.$d(new Date(this.deepTubwell.rehab_date_of_commission)), key2: this.$t('irriPumpInformation.accepted_participation'), val2: this.$n(this.deepTubwell.rehab_accepted_participation) },
          { key: this.$t('irriPumpInformation.project_name'), val: this.deepTubwell.project_name, key1: this.$t('irriPumpInformation.deep_tube_well_nature'), val1: this.deepTubwell.deep_tube_well_nature, key2: this.$t('irriPumpInformation.operator_name'), val2: this.deepTubwell.operator_name }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.date_of_excavation'), val: this.$d(new Date(this.deepTubwell.rehab_date_of_excavation)), key1: this.$t('irriPumpInformation.date_of_commission'), val1: this.$d(new Date(this.deepTubwell.rehab_date_of_commission)), key2: this.$t('irriPumpInformation.accepted_participation'), val2: this.$n(this.deepTubwell.rehab_accepted_participation) },
          { key: this.$t('irriPumpInformation.project_name'), val: this.deepTubwell.project_name, key1: this.$t('irriPumpInformation.deep_tube_well_nature'), val1: this.deepTubwell.deep_tube_well_nature, key2: this.$t('irriPumpInformation.operator_name'), val2: this.deepTubwell.operator_name }
          ]
      }
    },
    housing_pipe_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.ms'), val: this.$n(this.deepTubwell.ms), key1: this.$t('irriPumpInformation.fg'), val1: this.$n(this.deepTubwell.fg), key2: this.$t('irriPumpInformation.updc'), val2: this.$n(this.deepTubwell.updc) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.ms'), val: this.$n(this.deepTubwell.ms), key1: this.$t('irriPumpInformation.fg'), val1: this.$n(this.deepTubwell.fg), key2: this.$t('irriPumpInformation.updc'), val2: this.$n(this.deepTubwell.updc) }
          ]
      }
    },
    filter_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.filter_ms'), val: this.$n(this.deepTubwell.filter_ms), key1: this.$t('irriPumpInformation.filter_fg'), val1: this.$n(this.deepTubwell.filter_fg), key2: this.$t('irriPumpInformation.updc'), val2: this.$n(this.deepTubwell.filter_updc) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.filter_ms'), val: this.$n(this.deepTubwell.filter_ms), key1: this.$t('irriPumpInformation.filter_fg'), val1: this.$n(this.deepTubwell.filter_fg), key2: this.$t('irriPumpInformation.updc'), val2: this.$n(this.deepTubwell.filter_updc) }
          ]
      }
    },
    installed_column_pipe_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.dia'), val: this.$n(this.deepTubwell.dia), key1: this.$t('irriPumpInformation.amount'), val1: this.$n(this.deepTubwell.amount), key2: '', val2: '' }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.dia'), val: this.$n(this.deepTubwell.dia), key1: this.$t('irriPumpInformation.amount'), val1: this.$n(this.deepTubwell.amount), key2: '', val2: '' }
          ]
      }
    },
    vertical_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.make_o_model'), val: this.deepTubwell.vertical_make_o_model, key1: this.$t('irriPumpInformation.unit_consumption'), val1: this.$n(this.deepTubwell.vertical_unit_consumption), key2: this.$t('irriPumpInformation.power'), val2: this.$n(this.deepTubwell.vertical_power) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.make_o_model'), val: this.deepTubwell.vertical_make_o_model, key1: this.$t('irriPumpInformation.unit_consumption'), val1: this.$n(this.deepTubwell.vertical_unit_consumption), key2: this.$t('irriPumpInformation.power'), val2: this.$n(this.deepTubwell.vertical_power) }
          ]
      }
    },
    turbine_submersible_motor_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.make_o_model'), val: this.deepTubwell.turbine_make_o_model, key1: this.$t('irriPumpInformation.unit_consumption'), val1: this.$n(this.deepTubwell.turbine_unit_consumption), key2: this.$t('irriPumpInformation.power'), val2: this.$n(this.deepTubwell.turbine_power) },
          { key: this.$t('irriPumpInformation.head'), val: this.$n(this.deepTubwell.head), key1: this.$t('irriPumpInformation.discharge'), val1: this.$n(this.deepTubwell.discharge), key2: this.$t('irriPumpInformation.command_area'), val2: this.$n(this.deepTubwell.command_area) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.make_o_model'), val: this.deepTubwell.turbine_make_o_model, key1: this.$t('irriPumpInformation.unit_consumption'), val1: this.$n(this.deepTubwell.turbine_unit_consumption), key2: this.$t('irriPumpInformation.power'), val2: this.$n(this.deepTubwell.turbine_power) },
          { key: this.$t('irriPumpInformation.head'), val: this.$n(this.deepTubwell.head), key1: this.$t('irriPumpInformation.discharge'), val1: this.$n(this.deepTubwell.discharge), key2: this.$t('irriPumpInformation.command_area'), val2: this.$n(this.deepTubwell.command_area) }
          ]
      }
    },
    kharif_1_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.aus'), val: this.$n(this.deepTubwell.kharif_1_aus), key1: this.$t('irriPumpInformation.others'), val1: this.$n(this.deepTubwell.kharif_1_others), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.kharif_1_total) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.aus'), val: this.$n(this.deepTubwell.kharif_1_aus), key1: this.$t('irriPumpInformation.others'), val1: this.$n(this.deepTubwell.kharif_1_others), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.kharif_1_total) }
          ]
      }
    },
    kharif_2_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.aman'), val: this.$n(this.deepTubwell.kharif_2_aman), key1: this.$t('irriPumpInformation.others'), val1: this.$n(this.deepTubwell.kharif_2_others), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.kharif_2_total) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.aman'), val: this.$n(this.deepTubwell.kharif_2_aman), key1: this.$t('irriPumpInformation.others'), val1: this.$n(this.deepTubwell.kharif_2_others), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.kharif_2_total) }
          ]
      }
    },
    robi_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.borou'), val: this.$n(this.deepTubwell.borou), key1: this.$t('irriPumpInformation.mustard'), val1: this.$n(this.deepTubwell.mustard), key2: this.$t('irriPumpInformation.wheat'), val2: this.$n(this.deepTubwell.wheat) },
          { key: this.$t('irriPumpInformation.lentils'), val: this.$n(this.deepTubwell.lentils), key1: this.$t('irriPumpInformation.potato'), val1: this.$n(this.deepTubwell.potato), key2: this.$t('irriPumpInformation.vegetables'), val2: this.$n(this.deepTubwell.vegetables) },
          { key: this.$t('irriPumpInformation.corn'), val: this.$n(this.deepTubwell.corn), key1: this.$t('irriPumpInformation.total'), val1: this.$n(this.deepTubwell.robi_total) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.borou'), val: this.$n(this.deepTubwell.borou), key1: this.$t('irriPumpInformation.mustard'), val1: this.$n(this.deepTubwell.mustard), key2: this.$t('irriPumpInformation.wheat'), val2: this.$n(this.deepTubwell.wheat) },
          { key: this.$t('irriPumpInformation.lentils'), val: this.$n(this.deepTubwell.lentils), key1: this.$t('irriPumpInformation.potato'), val1: this.$n(this.deepTubwell.potato), key2: this.$t('irriPumpInformation.vegetables'), val2: this.$n(this.deepTubwell.vegetables) },
          { key: this.$t('irriPumpInformation.corn'), val: this.$n(this.deepTubwell.corn), key1: this.$t('irriPumpInformation.total'), val1: this.$n(this.deepTubwell.robi_total) }
          ]
      }
    },
    beneficial_farmer_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.actual'), val: this.$n(this.deepTubwell.actual), key1: this.$t('irriPumpInformation.barga'), val1: this.$n(this.deepTubwell.barga), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.beneficial_farmer_total) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.actual'), val: this.$n(this.deepTubwell.actual), key1: this.$t('irriPumpInformation.barga'), val1: this.$n(this.deepTubwell.barga), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.beneficial_farmer_total) }
          ]
      }
    },
    tot_uses_unit_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.start_reading'), val: this.$n(this.deepTubwell.start_reading), key1: this.$t('irriPumpInformation.end_reading'), val1: this.$n(this.deepTubwell.end_reading), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.total_uses_unit) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.start_reading'), val: this.$n(this.deepTubwell.start_reading), key1: this.$t('irriPumpInformation.end_reading'), val1: this.$n(this.deepTubwell.end_reading), key2: this.$t('irriPumpInformation.total'), val2: this.$n(this.deepTubwell.total_uses_unit) }
          ]
      }
    },
    irri_adai_descrip_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.hourly_used_unit'), val: this.$n(this.deepTubwell.hourly_used_unit), key1: this.$t('irriPumpInformation.tot_active_hour'), val1: this.$n(this.deepTubwell.total_active_hour), key2: this.$t('irriPumpInformation.hourly_irri_charge'), val2: this.$n(this.deepTubwell.hourly_irri_charge) },
          { key: this.$t('irriPumpInformation.recoverable_irri_payment'), val: this.$n(this.deepTubwell.recoverable_irri_payment), key1: this.$t('irriPumpInformation.collected_irri_payment'), val1: this.$n(this.deepTubwell.collected_irri_payment), key2: this.$t('irriPumpInformation.unpaid_money'), val2: this.$n(this.deepTubwell.unpaid_money) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.hourly_used_unit'), val: this.$n(this.deepTubwell.hourly_used_unit), key1: this.$t('irriPumpInformation.total_active_hour'), val1: this.$n(this.deepTubwell.total_active_hour), key2: this.$t('irriPumpInformation.hourly_irri_charge'), val2: this.$n(this.deepTubwell.hourly_irri_charge) },
          { key: this.$t('irriPumpInformation.recoverable_irri_payment'), val: this.$n(this.deepTubwell.recoverable_irri_payment), key1: this.$t('irriPumpInformation.collected_irri_payment'), val1: this.$n(this.deepTubwell.collected_irri_payment), key2: this.$t('irriPumpInformation.unpaid_money'), val2: this.$n(this.deepTubwell.unpaid_money) }
          ]
      }
    },
    mnge_ment_cost_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.tot_biddut_cost'), val: this.$n(this.deepTubwell.total_electricity_cost), key1: this.$t('irriPumpInformation.operator_salary'), val1: this.$n(this.deepTubwell.operator_salary), key2: this.$t('irriPumpInformation.maintance_cost'), val2: this.$n(this.deepTubwell.maintance_cost) },
          { key: this.$t('irriPumpInformation.other_cost'), val: this.$n(this.deepTubwell.other_cost), key1: this.$t('irriPumpInformation.tot_cost'), val1: this.$n(this.deepTubwell.total_cost), key2: this.$t('irriPumpInformation.tot_income'), val2: this.$n(this.deepTubwell.total_income) }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.tot_biddut_cost'), val: this.$n(this.deepTubwell.total_electricity_cost), key1: this.$t('irriPumpInformation.operator_salary'), val1: this.$n(this.deepTubwell.operator_salary), key2: this.$t('irriPumpInformation.maintance_cost'), val2: this.$n(this.deepTubwell.maintance_cost) },
          { key: this.$t('irriPumpInformation.other_cost'), val: this.$n(this.deepTubwell.other_cost), key1: this.$t('irriPumpInformation.tot_cost'), val1: this.$n(this.deepTubwell.total_cost), key2: this.$t('irriPumpInformation.tot_income'), val2: this.$n(this.deepTubwell.total_income) }
          ]
      }
    },
    mnge_ment_cost_bottom_columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('irriPumpInformation.ganku_details'), val: this.deepTubwell.ganku_details },
          { key: this.$t('irriPumpInformation.super_v_mechanic'), val: this.deepTubwell.super_v_mechanic },
          { key: this.$t('irriPumpInformation.super_v_higher_engr'), val: this.deepTubwell.super_v_higher_engr },
          { key: this.$t('irriPumpInformation.comments'), val: this.deepTubwell.comments }
        ]
      } else {
          return [
          { key: this.$t('irriPumpInformation.ganku_details'), val: this.deepTubwell.ganku_details },
          { key: this.$t('irriPumpInformation.super_v_mechanic'), val: this.deepTubwell.super_v_mechanic },
          { key: this.$t('irriPumpInformation.super_v_higher_engr'), val: this.deepTubwell.super_v_higher_engr },
          { key: this.$t('irriPumpInformation.comments'), val: this.deepTubwell.comments }
          ]
      }
    }
  },
  methods: {
  }
}
</script>
<style lang="scss">
  .details_report {
    font-size: 8px;
    color: #000;
    h5 {
      color: green !important;
      font-size: 12px !important;
    }
    .table-bordered td:nth-child(2n+1) {
      font-weight: 700;
    }
    .table-bordered th {
      text-align: center;
      font-size: 9px !important;
      font-weight: 900 !important;
    }
    .table-bordered tr td {
      font-size: 9px !important;
      text-align: center;
    }
  }
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
</style>
